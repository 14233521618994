import { AccountAupService } from './../../../services/account/account-aup.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { Router } from '@angular/router';
import { LocalityService } from 'src/app/services/location/locality.service';
import { LinkService } from 'src/app/services/general/link.service';
import { GuestControlService } from 'src/app/services/guest/guest-control.service';
import { ModalController, Platform } from '@ionic/angular';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import readXlsxFile from 'read-excel-file';
import { nanoid } from 'nanoid';
import { Subscription } from 'rxjs';

import { LanguageService } from 'src/app/services/general/language.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { SeatingManageService } from 'src/app/services/seating/seating-manage.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';
import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';
import { CategoryService } from 'src/app/services/setting/category.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { Guest } from 'src/app/interfaces/guest';
import { Language, SettingField } from 'src/app/interfaces/database';
import { Seating } from 'src/app/interfaces/seating';
import { Group } from 'src/app/interfaces/group';
import { ImportError } from 'src/app/interfaces/import';
import { ImportCaption } from 'src/app/types/import';
import { Mobile } from 'src/app/interfaces/general';
import { UpdateBy } from 'src/app/interfaces/user';
import { MobileService } from 'src/app/services/general/mobile.service';
import { DynamicUrl, GuestListTemplateCloudUrl, HowImportUrl } from 'src/app/commons/url';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-guest-import',
  templateUrl: './guest-import.component.html',
  styleUrls: ['./guest-import.component.scss'],
})
export class GuestImportComponent implements OnInit, OnDestroy {

  // @ViewChild('file') file: ElementRef;
  @ViewChild('file', { static: false }) file;
  /**
   * Import step
   */
  step: number;

  /**
   * Error type
   */
  errorType: string;
  /**
   * Error row list
   */
  errorRowList: number[];
  /**
   * Error detail list
   */
  errorDetailList: ImportError[];

  /**
   * Invited by list
   */
  invitedByList: SettingField[];
  /**
   * Category list
   */
  categoryList: SettingField[];
  /**
   * Dietary request list
   */
  dietaryReqList: SettingField[];
  /**
   * Special request list
   */
  specialReqList: SettingField[];
  /**
   * Seating list
   */
  seatingList: Seating[];

  /**
   * New invited by list
   */
  newInvitedByList: SettingField[];
  /**
   * New category list
   */
  newCategoryList: SettingField[];
  /**
   * New dietary request list
   */
  newDietaryReqList: SettingField[];
  /**
   * New special request list
   */
  newSpecialReqList: SettingField[];
  /**
   * New seating list
   */
  newSeatingList: string[];
  /**
   * Guest list
   */
  guestList: Guest[];
  /**
   * Group list
   */
  groupList: Group[];
  country = this.localityService.getAccountCountry();

  isHybrid: boolean;

  /**
   * Invited by subscription
   */
  private invitedByListSubscription: Subscription;
  /**
   * Category list subscription
   */
  private categoryListSubscription: Subscription;
  /**
   * Dietary request list subscription
   */
  private dietaryReqListSubscription: Subscription;
  /**
   * Special request list subscription
   */
  private specialReqListSubscription: Subscription;
  /**
   * Seating list subscription
   */
  private seatingListSubscription: Subscription;

  private systemInvitedByList: any;
  private systemCategoryList: any;
  private systemDietaryReqList: any;
  private systemSpecialReqList: any;
  /**
   * Constructor
   * @param platform Platform
   * @param translate translate
   * @param modalController modal controller
   * @param fileOpener file opener
   * @param languageService language service
   * @param invitedByService invited by service
   * @param categoryService category service
   * @param dietaryReqService dietary request service
   * @param specialReqService special request service
   * @param seatingService seating service
   * @param seatingManageService seating manage service
   * @param guestManageService guest manage service
   * @param groupManageService group manage service
   * @param popupService popup service
   * @param functionService function service
   * @param errorService error service
   */
  constructor(
    private platform: Platform,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private modalController: ModalController,
    private languageService: LanguageService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private seatingManageService: SeatingManageService,
    private guestManageService: GuestManageService,
    private guestControlService: GuestControlService,
    private groupManageService: GroupManageService,
    private accountAupService: AccountAupService,
    private linkService: LinkService,
    private localityService: LocalityService,
    private mobileService: MobileService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private errorService: ErrorService,
    private updateByService: UpdateByService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      this.unwatch();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.initial();
    this.watch();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.isHybrid = this.platform.is('hybrid');
    this.unwatch();
  }

  /**
   * Watch
   */
  watch() {
    this.watchInvitedBy();
    this.watchCategory();
    this.watchDietaryReq();
    this.watchSpecialReq();
    this.watchSeatingList();
    this.watchSystemFieldList();
  }

  /**
   * Unwatch
   */
  unwatch() {
    this.unwatchInvitedBy();
    this.unwatchCategory();
    this.unwatchDietaryReq();
    this.unwatchSpecialReq();
    this.unwatchSeatingList();
  }

  /**
   * Initial
   */
  initial() {
    this.country = this.localityService.getAccountCountry();
    this.invitedByList = [];
    this.categoryList = [];
    this.dietaryReqList = [];
    this.specialReqList = [];
    this.seatingList = [];

    this.newInvitedByList = [];
    this.newCategoryList = [];
    this.newDietaryReqList = [];
    this.newSpecialReqList = [];
    this.newSeatingList = [];

    this.guestList = [];
    this.groupList = [];
    this.errorRowList = [];
    this.errorDetailList = [];

    this.clearFileInput();
    this.step = 1;
  }

  /**
   * Watch invited by changes
   */
  async watchInvitedBy() {
    if (!this.invitedByListSubscription) {
      this.invitedByListSubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
      });
    }
    
  }

  /**
   * Unwatch invited by
   */
  async unwatchInvitedBy() {
    if (this.invitedByListSubscription) {
      this.invitedByListSubscription.unsubscribe();
      this.invitedByListSubscription = null;
    }
  }

  /**
   * Watch category
   */
  async watchCategory() {
    if (!this.categoryListSubscription) {
      this.categoryListSubscription = this.categoryService.observableCategoryList.subscribe((categoryList: SettingField[]) => {
        this.categoryList = this.categoryService.getCategoryList();
      });
    }
    
  }

  /**
   * Unwatch category
   */
  async unwatchCategory() {
    if (this.categoryListSubscription) {
      this.categoryListSubscription.unsubscribe();
      this.categoryListSubscription = null;
    }
  }

  /**
   * Watch dietary request
   */
  async watchDietaryReq() {
    if (!this.dietaryReqListSubscription) {
      this.dietaryReqListSubscription = this.dietaryReqService.observableDietaryReqList.subscribe((dietaryReqList: SettingField[]) => {
        this.dietaryReqList = this.dietaryReqService.getDietaryReqList();
      });
    }
    
  }

  /**
   * Unwatch dietary request
   */
  async unwatchDietaryReq() {
    if (this.dietaryReqListSubscription) {
      this.dietaryReqListSubscription.unsubscribe();
      this.dietaryReqListSubscription = null;
    }
  }

  /**
   * Watch special request
   */
  async watchSpecialReq() {
    if (!this.specialReqListSubscription) {
      this.specialReqListSubscription = this.specialReqService.observableSpecialReqList.subscribe((specialReqList: SettingField[]) => {
        this.specialReqList = this.specialReqService.getSpecialReqList();
      });
    }
    
  }

  /**
   * Unwatch special request
   */
  async unwatchSpecialReq() {
    if (this.specialReqListSubscription) {
      this.specialReqListSubscription.unsubscribe();
      this.specialReqListSubscription = null;
    }
  }

  /**
   * Watch seating list and get seating
   */
  async watchSeatingList() {
    if (!this.seatingListSubscription) {
      this.seatingListSubscription = this.seatingService.observableSeatingList.subscribe((seatingList: Seating[]) => {
        this.seatingList = seatingList;
      });
    }
    
  }

  /**
   * Unwatch seating list
   */
  async unwatchSeatingList() {
    if (this.seatingListSubscription) {
      this.seatingListSubscription.unsubscribe();
      this.seatingListSubscription = null;
    }
  }

  watchSystemFieldList() {
    if (!this.systemInvitedByList) {
      this.systemInvitedByList = {};
    }
    if (!this.systemCategoryList) {
      this.systemCategoryList = {};
    }
    if (!this.systemDietaryReqList) {
      this.systemDietaryReqList = {};
    }
    if (!this.systemSpecialReqList) {
      this.systemSpecialReqList = {};
    }
    const translation = this.languageService.translation;
    this.languageService.languageList.forEach((lang: Language) => {
      if (lang?.code && translation?.[lang.code]) {
        if (!this.systemInvitedByList?.[lang.code]) {
          this.systemInvitedByList[lang.code] = {};
        }
        if (translation[lang.code]?.LIST?.invited_by) {
          this.systemInvitedByList[lang.code] = translation[lang.code]?.LIST?.invited_by;
        }
        if (!this.systemCategoryList?.[lang.code]) {
          this.systemCategoryList[lang.code] = {};
        }
        if (translation[lang.code]?.LIST?.category) {
          this.systemCategoryList[lang.code] = translation[lang.code]?.LIST?.category;
        }
        if (!this.systemDietaryReqList?.[lang.code]) {
          this.systemDietaryReqList[lang.code] = {};
        }
        if (translation[lang.code]?.LIST?.dietary_req) {
          this.systemDietaryReqList[lang.code] = translation[lang.code]?.LIST?.dietary_req;
        }
        if (!this.systemSpecialReqList?.[lang.code]) {
          this.systemSpecialReqList[lang.code] = {};
        }
        if (translation[lang.code]?.LIST?.special_req) {
          this.systemSpecialReqList[lang.code] = translation[lang.code]?.LIST?.special_req;
        }
        // const translate = this.functionService.cloneDeep(this.translate);
        // const subscription = translate.getTranslation(lang.code).subscribe((res: any) => {
        //   if (res?.LIST?.invited_by) {
        //     if (!this.systemInvitedByList?.[lang.code]) {
        //       this.systemInvitedByList[lang.code] = {};
        //     }
        //     this.systemInvitedByList[lang.code] = res.LIST.invited_by;
        //   }
        //   if (res?.LIST?.category) {
        //     if (!this.systemCategoryList?.[lang.code]) {
        //       this.systemCategoryList[lang.code] = {};
        //     }
        //     this.systemCategoryList[lang.code] = res.LIST.category;
        //   }
        //   subscription.unsubscribe();
        // });
      }
    });
  }

  /**
   * Back
   */
  back() {
    if (this.step === 1) {
      this.dismissModal();
    } else if (this.step === 2) {
      this.initial();
    }
  }

  /**
   * Dismiss guest edit modal
   * @param data new data
   * @param dismiss dismiss previous modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  async promptOpenWeb() {
    const confirm = await this.popupService.presentConfirm(
      this.translate.instant('IMPORT.msg.web')
    );
    confirm.onWillDismiss().then((result: any) => {
      if (result?.data?.confirm) {
        this.linkService.openUrl(DynamicUrl.long.home, true);
      }
    });
  }

  /**
   * Open excel file
   */
  async openExcel() {
    let fileName = 'thebigday Guest List Template - ';
    const path = 'assets/wedding/excel/guest-list/' + fileName;
    const language = this.languageService.getUserLanguage()?.code ? this.languageService.getUserLanguage()?.code : 'en';
    if (language) {
      fileName = path + language?.toString()?.toLowerCase() + '.xlsx';
    } else {
      fileName = path + 'en.xlsx';
    }

    if (this.platform.is('hybrid')) {
      this.openCloudSheet();
      // await this.popupService.presentLoading();
      // // this.promptOpenWeb();
      // try {
      //   this.http.get(path, { responseType: 'blob' }).subscribe(async (fileBlob: Blob) => {
      //     try {
      //       // Read the blob data as a base64 string
      //       const base64Data = await this.convertBlobToBase64(fileBlob);
      //       console.log(base64Data);
      //       // Write the file to a writable location (e.g., Documents directory)
      //       const savedFile = await Filesystem.writeFile({
      //         path: fileName,
      //         data: base64Data,
      //         directory: Directory.Cache,
      //       });
    
      //       // Get the file URI
      //       const fileUri = savedFile.uri;
    
      //       const fileOpenerOptions: FileOpenerOptions = {
      //         filePath: fileUri,
      //         openWithDefault: true,
      //       };
      //       await FileOpener.open(fileOpenerOptions);
      //       this.popupService.dismissLoading();
      //       // Open the file using the File Opener plugin
      //       // FileOpener
      //       //   .open(fileUri, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      //       //   .then(() => console.log('File is opened'))
      //       //   .catch((e) => console.error('Error opening file', e));
      //     } catch (error) {
      //       console.error('Error copying file', error);
      //       this.popupService.dismissLoading();
      //     }
      //   });
      // } catch (err: any) {
      //   this.errorService.logError(err);
      // }
    } else {
      this.openUrl(fileName);
    }
  }

  async openCloudSheet() {
    const language = this.languageService.getUserLanguage()?.code ? this.languageService.getUserLanguage()?.code : 'en';
    if (GuestListTemplateCloudUrl?.[language]) {
      await this.openUrl(GuestListTemplateCloudUrl[language]);
    }
  }

  fileClick() {
    if (this.file) {
      this.file.nativeElement.click();
    }
  }

  /**
   * On file change event
   * @param event File change event
   */
  async onFileChange(event: any) {
    readXlsxFile(event?.target?.files?.[0], { sheet: this.translate.instant('IMPORT.sheet.import') }).then((data: any) => {
      if (data) {
        this.importGuestList(data);
      } else {
        this.promptFileError(this.translate.instant('IMPORT.err.empty'));
      }
    }).catch((err) => {
      this.errorService.logError(err);
      if (err?.toString() && err?.toString()?.indexOf('Sheet "' + this.translate.instant('IMPORT.sheet.import') + '" not found in the *.xlsx file.') !== -1) {
        this.promptFileError(this.translate.instant('IMPORT.err.sheet'));
      } else {
        this.promptFileError(this.translate.instant('IMPORT.err.file'));
      }
      // this.promptFileError(err);
    });
  }

  clearFileInput() {
    if (this.file?.nativeElement?.value) {
      this.file.nativeElement.value = '';
    }
  }

  async promptFileError(msg?: string) {
    const alert: HTMLIonModalElement = await this.popupService.presentAlert(msg);
    alert.onDidDismiss().then(() => {
      this.initial();
    });
  }

  /**
   * Get mutual invited by
   * @returns Mutual invited by setting field
   */
  getMutualSettingField(): SettingField {
    const settingField: SettingField = {
      value: 'mutual',
      custom: false
    };
    return settingField;
  }

  /**
   * Get other setting field
   * @returns Other setting field
   */
  getOtherSettingField(): SettingField {
    const settingField: SettingField = {
      value: 'others',
      custom: false
    };
    return settingField;
  }

  /**
   * Get none setting field
   * @returns None setting field
   */
  getNoneSettingField(): SettingField {
    const settingField: SettingField = {
      value: 'none',
      custom: false
    };
    return settingField;
  }

  /**
   * Validate max pax
   */
  exceedMaxGuest(): boolean {
    if (this.guestControlService.exceedMaxGuest(this.guestList.length)) {
      return true;
    }
    return false;
  }

  /**
   * Import guest list
   * @param data Data
   */
  async importGuestList(data: any) {
    await this.popupService.presentLoading();

    if (data?.length > 1) {
      this.guestList = [];
      this.groupList = [];
      this.errorRowList = [];
      this.errorDetailList = [];

      const nameIndex = this.getCaptionIndex(data[0], 'name');
      const nicknameIndex = this.getCaptionIndex(data[0], 'nickname');
      const invitedByIndex = this.getCaptionIndex(data[0], 'invitedBy');
      const categoryIndex = this.getCaptionIndex(data[0], 'category');
      const paxIndex = this.getCaptionIndex(data[0], 'pax');
      const seatingIndex = this.getCaptionIndex(data[0], 'seating');
      const remarkIndex = this.getCaptionIndex(data[0], 'remark');
      const mobileIndex = this.getCaptionIndex(data[0], 'mobile');
      const emailIndex = this.getCaptionIndex(data[0], 'email');
      const dietaryReqIndex = this.getCaptionIndex(data[0], 'dietaryReq');
      const specialReqIndex = this.getCaptionIndex(data[0], 'specialReq');

      if (nameIndex >= 0 && paxIndex >= 0) {
        data?.forEach((row: any, index: number) => {
          if (index > 0) {
            let pax = row?.[paxIndex];
            const name = row?.[nameIndex]?.toString()?.trim();
            try {
              let flag = true;

              const missingField = this.validateGuestField(row, nameIndex, paxIndex);
              if (missingField?.length) {
                flag = false;
                this.updateErrorRowListIndex(index);
                missingField.forEach((field: ImportCaption) => {
                  this.updateErrorDetailList(index, field, false, true);
                });
              }

              pax = pax && this.functionService.isNumber(pax) ? parseInt(pax, 10) : null;
              // if (!pax) {
              //   this.updateErrorRowListIndex(index);
              //   this.updateErrorDetailList(index, 'pax', false, true);
              // }
           
              if (pax && pax >= 1) {
                  if (!name) {
                    this.updateErrorRowListIndex(index);
                    this.updateErrorDetailList(index, 'name', false, true);
                  } else {
                    if (pax === 1) {
                      const guest: Guest = this.generateGuest(row, nameIndex, nicknameIndex, invitedByIndex,
                        categoryIndex, seatingIndex, remarkIndex, mobileIndex, emailIndex, dietaryReqIndex, specialReqIndex);
                      let invalid = false;

                      if (!this.validateMobile(guest, row, mobileIndex)) {
                        this.updateErrorRowListIndex(index);
                        this.updateErrorDetailList(index, 'mobile', true, false);
                        invalid = true;
                      }
                      if (!this.validateEmail(guest, row, emailIndex)) {
                        this.updateErrorRowListIndex(index);
                        this.updateErrorDetailList(index, 'email', true, false);
                        invalid = true;
                      }
                      if (!invalid) {
                        this.guestList.push(guest);
                      }
                    } else if (pax > 1) {
                      const groupId = this.guestManageService.groupId;

                      let mobile: Mobile = {
                        no: '',
                        country: '',
                        code: 0,
                      };
                      if (mobileIndex !== -1 && row?.[mobileIndex]) {
                        mobile = this.mobileService.generate(row?.[mobileIndex]);
                      }
                      
                      const group: Group = {
                        groupId,
                        groupName: this.prepareString(row?.[nameIndex]),
                        email: emailIndex >= 0 && row?.[emailIndex] ? this.prepareString(row[emailIndex]) : '',
                        mobile,
                        memberList: [],
                        status: {
                          checkin: false,
                          gift: false,
                          deleted: false,
                          rsvp: false,
                          qrcode: false
                        }
                      };
                      

                      for (let i = 1; i <= pax; i++) {
                        const guest: Guest = this.generateGuest(row, nameIndex, nicknameIndex, invitedByIndex,
                          categoryIndex, seatingIndex, remarkIndex, mobileIndex, emailIndex, dietaryReqIndex, specialReqIndex);
                        let invalid = false;

                        if (!this.validateMobile(guest, row, mobileIndex)) {
                          this.updateErrorRowListIndex(index);
                          this.updateErrorDetailList(index, 'mobile', true, false);
                          invalid = true;
                        }
                        if (!this.validateEmail(guest, row, emailIndex)) {
                          this.updateErrorRowListIndex(index);
                          this.updateErrorDetailList(index, 'email', true, false);
                          invalid = true;
                        }
                        if (!invalid) {
                          guest.groupId = groupId;
                          this.guestList.push(guest);
                        }
                      }
                      if (this.validateGroup(group)) {
                        this.groupList.push(group);
                      } else {
                        this.updateErrorRowListIndex(index);
                        this.updateErrorDetailList(index, 'name', false, false);
                      }
                    }
                  }
                } else if (row?.[paxIndex]) {
                  this.updateErrorRowListIndex(index);
                  this.updateErrorDetailList(index, 'pax', true, false);
                }
            } catch (err) {
              this.errorService.logError(err);
              this.updateErrorRowListIndex(index);
              this.updateErrorDetailList(index, 'pax', true, false);
            }
          }
        });
        this.step = 2;
        this.popupService.dismissLoading();
      } else {
        this.promptFileError(
          this.translate.instant('IMPORT.err.col')
        );
      }
    } else {
      await this.popupService.dismissLoading();
      this.promptFileError(this.translate.instant('IMPORT.err.empty'));
    }
  }

  updateErrorRowListIndex(index: number) {
    if (this.errorRowList.indexOf(index) === -1) {
      this.errorRowList.push(index);
    }
  }

  updateErrorDetailList(row: number, field: ImportCaption, invalid: boolean, empty: boolean) {
    const index = this.errorDetailList.findIndex((importError: ImportError) => {
      return importError?.row === row && importError?.field === field && importError?.invalid === invalid && importError?.empty === empty;
    });
    if (index === -1) {
      this.errorDetailList.push({ row, field, invalid, empty });
    }
  }

  /**
   * Generate guest data
   * @param row Row data
   * @param nameIndex Name index
   * @param nicknameIndex nickname index
   * @param invitedByIndex invited by index
   * @param categoryIndex category index
   * @param seatingIndex seating index
   * @param remarkIndex remark index
   * @returns Guest data
   */
  generateGuest(row: any, nameIndex: number, nicknameIndex: number, invitedByIndex: number,
                categoryIndex: number, seatingIndex: number, remarkIndex: number, mobileIndex: number, emailIndex: number, dietaryReqIndex: number, specialReqIndex: number) {
    let mobile: Mobile = {
      no: '',
      country: '',
      code: 0
    };
    if (mobileIndex !== -1 && row?.[mobileIndex]) {
      mobile = this.mobileService.generate(row?.[mobileIndex]);
    }
    const guest: Guest = {
      guestId: this.guestManageService.guestId,
      name: nameIndex >= 0 && row?.[nameIndex] ? this.prepareString(row[nameIndex]) : '',
      nickname: nicknameIndex >= 0 && row?.[nicknameIndex] ? this.prepareString(row[nicknameIndex]) : '',
      invitedBy: [ this.generateInvitedBy(row?.[invitedByIndex]) ],
      category: [ this.generateCategory(row?.[categoryIndex]) ],
      seating: this.generateSeatingList(row?.[seatingIndex]),
      groupId: '',
      email: emailIndex >= 0 && row?.[emailIndex] ? this.prepareString(row[emailIndex]) : '',
      mobile,
      dietaryReq: [ this.generateDietaryReq(row?.[dietaryReqIndex]) ],
      specialReq: [ this.generateSpecialReq(row?.[specialReqIndex]) ],
      giftList: [],
      remark: remarkIndex >= 0 && row?.[remarkIndex] ? this.prepareString(row[remarkIndex]) : '',
      status: {
        attending: 'attending',
        checkin: false,
        gift: false,
        deleted: false,
        rsvp: false,
        qrcode: false
      }
    };
    return guest;
  }

  /**
   * Save import data
   */
  async save() {
    if (this.guestList?.length) {
      if (!this.exceedMaxGuest() && !this.accountAupService.isExceed()) {
        await this.popupService.presentLoading();
        const updateBy: UpdateBy = this.updateByService.generateUpdateBy();
        if (this.newInvitedByList?.length) {
          const invitedByList = [...new Set([...this.invitedByList, ...this.newInvitedByList])];
          await this.invitedByService.saveInvitedBy(invitedByList);
        }
        if (this.newCategoryList?.length) {
          const categoryList = [...new Set([...this.categoryList, ...this.newCategoryList])];
          await this.categoryService.saveCategory(categoryList);
        }
        if (this.newDietaryReqList?.length) {
          const dietaryReqList = [...new Set([...this.dietaryReqList, ...this.newDietaryReqList])];
          await this.dietaryReqService.saveDietaryReq(dietaryReqList);
        }
        if (this.newSpecialReqList?.length) {
          const specialReqList = [...new Set([...this.specialReqList, ...this.newSpecialReqList])];
          await this.specialReqService.saveSpecialReq(specialReqList);
        }
        if (this.newSeatingList?.length) {
          const order = this.seatingService.getMaxSeatingOrder();
          const seatingList: Seating[] = this.newSeatingList.map((name: string, index: number) => {
            const seating: Seating = {
              seatingId: nanoid(),
              name,
              invitedBy: [],
              category: [],
              reserved: false,
              maxGuest: this.seatingSettingService.getGuestPerSeating(),
              count: 1,
              order: order + index,
            };
            return seating;
          });
          await this.seatingManageService.saveNewSeating(seatingList);
        }

        if (this.groupList?.length) {
          const groupIdList: string[] = [];
          this.groupList.forEach((group: Group) => {
            group.createBy = updateBy;
            group.updateBy = updateBy;
            if (groupIdList.indexOf(group.groupId) === -1) {
              groupIdList.push(group.groupId);
            }
          });
          await this.groupManageService.saveGroupList(groupIdList, null, this.groupList);
        }

        const guestIdList: string[] = [];
        this.guestList.forEach((guest: Guest) => {
          if (guest?.guestId) {
            guest.createBy = updateBy;
            guest.updateBy = updateBy;
            if (guestIdList.indexOf(guest?.guestId) === -1) {
              guestIdList.push(guest.guestId);
            }
          }
        });
        await this.guestManageService.saveGuest(null, guestIdList, 'new', this.guestList, true);

        this.dismissModal();
        this.popupService.dismissLoading();
        this.popupService.saveSuccessToast();
        this.router.navigate(['/tabs-guest/home']);
      }
    }
  }

  /**
   * Get caption index
   * @param row row data
   * @param field import caption field
   */
  getCaptionIndex(row: any, field: ImportCaption): number {
    if (field && row) {
      return row?.findIndex((x: string) => {
        return this.getCaption(x, field);
      });
    }
    return -1;
  }

  getCaption(caption: string, field: ImportCaption): boolean {
    caption = caption?.trim()?.toLowerCase();
    if (caption?.endsWith('*')) {
      // If yes, remove the last character ('*')
      caption = caption.slice(0, -1);
    }
    if (field === 'name') {
      if (caption === this.translate.instant('GUEST.lbl.guest_name').toLowerCase()) {
        return true;
      } else if (caption === this.translate.instant('IMPORT.caption.name').toLowerCase()) {
        return true;
      }
    } else if (field === 'nickname') {
      if (caption === this.translate.instant('GUEST.lbl.guest_nickname').toLowerCase()) {
        return true;
      }
    } else if (field === 'invitedBy') {
      if (caption === this.translate.instant('GUEST.lbl.invited_by').toLowerCase()) {
        return true;
      }
    } else if (field === 'category') {
      if (caption === this.translate.instant('LBL.category').toLowerCase()) {
        return true;
      } else if (caption === this.translate.instant('IMPORT.caption.category').toLowerCase()) {
        return true;
      }
    } else if (field === 'pax') {
      if (caption === this.translate.instant('GUEST.lbl.pax').toLowerCase()) {
        return true;
      }
    } else if (field === 'seating') {
      if (caption === this.translate.instant('MODULE.action.guest.seating').toLowerCase()) {
        return true;
      } else if (caption === this.translate.instant('IMPORT.caption.seating').toLowerCase()) {
        return true;
      }
    } else if (field === 'remark') {
      if (caption === this.translate.instant('GUEST.lbl.remark').toLowerCase()) {
        return true;
      }
    } else if (field === 'mobile') {
      if (caption === this.translate.instant('MOBILE.lbl.no').toLowerCase()) {
        return true;
      }
    } else if (field === 'email') {
      if (caption === this.translate.instant('LBL.email').toLowerCase()) {
        return true;
      }
    } else if (field === 'dietaryReq') {
      if (caption === this.translate.instant('GUEST.lbl.dietary_req').toLowerCase()) {
        return true;
      }
    } else if (field === 'specialReq') {
      if (caption === this.translate.instant('GUEST.lbl.special_req').toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  validateGuestField(row: any, nameIndex: number, paxIndex: number): ImportCaption[] {
    const field: ImportCaption[] = [];

    if (nameIndex === -1 || !row?.[nameIndex]) {
      field.push('name');
    }

    if (paxIndex === -1 || !row?.[paxIndex]) {
      field.push('pax');
    }

    return field;
  }

  /**
   * Validate guest
   * @param guest Guest
   * @returns true if valid guest
   */
  // validateGuest(guest: Guest): boolean {
  //   if (guest?.guestId && guest?.name && guest?.invitedBy?.length && guest?.category?.length &&
  //     guest?.dietaryReq?.length && guest?.specialReq?.length) {
  //     return true;
  //   }
  //   return false;
  // }

  validateMobile(guest: Guest, row: any, mobileIndex: number) {
    if (mobileIndex !== -1 && row?.[mobileIndex] && !guest?.mobile?.no) {
      return false;
    }
    return true;
  }

  validateEmail(guest: Guest, row: any, emailIndex: number) {
    if (guest?.email && !this.functionService.validateEmail(guest.email)) {
      return false;
    }
    return true;
  }

  /**
   * Validate group
   * @param group Group
   * @returns true if valid group
   */
  validateGroup(group: Group) {
    if (group?.groupId && group?.groupName) {
      return true;
    }
    return false;
  }

  /**
   * Generate invited by, remove new line, trim space, create new invited by if not existed
   * @param value invited by text
   * @returns Invited by setting field
   */
  generateInvitedBy(value: string): SettingField {
    if (value) {
      value = this.prepareString(value);

      const { invitedBy, existed } = this.checkSystemInvitedBy(value);
      if (invitedBy) {
        if (!existed) {
          const index = this.newInvitedByList?.findIndex((x: SettingField) => {
            if (x?.value?.toString()?.toLowerCase()?.trim() === value?.toString()?.toLowerCase()?.trim()) {
              return true;
            }
            return false;
          });
          if (index === -1) {
            invitedBy.id = this.functionService.randomId() + '_' + value;
            this.newInvitedByList.push(invitedBy);
          } else {
            return this.newInvitedByList[index];
          }
        }
        return invitedBy;
      }
    }
    return this.getMutualSettingField();
  }

  /**
   * Generate category, remove new line, trim space, create new category if not existed
   * @param value category text
   * @returns Category setting field
   */
  generateCategory(value: string): SettingField {
    if (value) {
      value = this.prepareString(value);

      const { category, existed } = this.checkSystemCategory(value);
      if (category) {
        if (!existed) {
          const index = this.newCategoryList?.findIndex((x: SettingField) => {
            if (x?.value?.toString()?.toLowerCase()?.trim() === value?.toString()?.toLowerCase()?.trim()) {
              return true;
            }
            return false;
          });
          if (index === -1) {
            category.id = this.functionService.randomId() + '_' + value;
            this.newCategoryList.push(category);
          } else {
            return this.newCategoryList[index];
          }
        }
        return category;
      }
    }
    return this.getOtherSettingField();
  }

  generateDietaryReq(value: string): SettingField {
    if (value) {
      value = this.prepareString(value);

      const { dietaryReq, existed } = this.checkSystemDietaryReq(value);
      if (dietaryReq) {
        if (!existed) {
          const index = this.newDietaryReqList?.findIndex((x: SettingField) => {
            if (x?.value?.toString()?.toLowerCase()?.trim() === value?.toString()?.toLowerCase()?.trim()) {
              return true;
            }
            return false;
          });
          if (index === -1) {
            dietaryReq.id = this.functionService.randomId() + '_' + value;
            this.newDietaryReqList.push(dietaryReq);
          } else {
            return this.newDietaryReqList[index];
          }
        }
        return dietaryReq;
      }
    }
    return this.getNoneSettingField();
  }

  generateSpecialReq(value: string): SettingField {
    if (value) {
      value = this.prepareString(value);

      const { specialReq, existed } = this.checkSystemSpecialReq(value);
      if (specialReq) {
        if (!existed) {
          const index = this.newSpecialReqList?.findIndex((x: SettingField) => {
            if (x?.value?.toString()?.toLowerCase()?.trim() === value?.toString()?.toLowerCase()?.trim()) {
              return true;
            }
            return false;
          });
          if (index === -1) {
            specialReq.id = this.functionService.randomId() + '_' + value;
            this.newSpecialReqList.push(specialReq);
          } else {
            return this.newSpecialReqList[index];
          }
        }
        return specialReq;
      }
    }
    return this.getNoneSettingField();
  }

  /**
   * Generate seating, remove new line, trim space, create new seating if not existed
   * @param seating Seating
   * @returns Seating name
   */
  generateSeatingList(seating: string): string {
    if (seating) {
      seating = this.prepareString(seating);
      const index = this.seatingList?.findIndex((x: Seating) => x?.name?.toLowerCase() === seating?.toLowerCase() );
      if (index === -1) {
        const newIndex = this.newSeatingList?.findIndex((x: string) => x?.toLowerCase() === seating?.toLowerCase() );
        if (newIndex === -1) {
          this.newSeatingList.push(seating.toString());
        } else {
          return this.newSeatingList[newIndex];
        }
      } else {
        return this.seatingList[index]?.name;
      }
    }
    return seating ? seating : '';
  }

  prepareString(value: string) {
    return this.functionService.replaceNewLine(value);
  }

  getEmtpyRowErrDetail(row: number) {
    let msg = '';
    const emptyList = [ ...this.errorDetailList ]?.filter((errDetail: ImportError) => {
      return errDetail?.row === row && errDetail?.empty ? true : false;
    });
    if (emptyList?.length) {
      emptyList.forEach((err: ImportError) => {
        const field = this.getErrField(err);
        if (field) {
          if (msg) {
            msg = msg + ', ';
          }
          msg = msg + field;
        }
      });
    }
    if (msg) {
      return this.translate.instant('IMPORT.err.row.empty') + msg;
    } else {
      return '';
    }
  }

  getInvalidRowErrDetail(row: number) {
    let msg = '';
    const invalidList = [ ...this.errorDetailList ]?.filter((errDetail: ImportError) => {
      return errDetail?.row === row && errDetail?.invalid ? true : false;
    });
    if (invalidList?.length) {
      invalidList.forEach((err: ImportError) => {
        const field = this.getErrField(err);
        if (field) {
          if (msg) {
            msg = msg + ', ';
          }
          msg = msg + field;
        }
      });
    }
    if (msg) {
      return this.translate.instant('IMPORT.err.row.invalid') + msg;
    } else {
      return '';
    }
  }

  getErrField(err: ImportError): string {
    let field = '';
    if (err?.field) {         
      if (err.field === 'name') {
        field = this.translate.instant('GUEST.lbl.guest_name');
      } else if (err.field === 'nickname') {
        field = this.translate.instant('GUEST.lbl.guest_nickname');
      } else if (err.field === 'pax') {
        field = this.translate.instant('GUEST.lbl.pax');
      } else if (err.field === 'invitedBy') {
        field = this.translate.instant('GUEST.lbl.invited_by');
      } else if (err.field === 'category') {
        field = this.translate.instant('LBL.category');
      } else if (err.field === 'remark') {
        field = this.translate.instant('GUEST.lbl.remark');
      } else if (err.field === 'seating') {
        field = this.translate.instant('MODULE.action.guest.seating');
      } else if (err.field === 'mobile') {
        field = this.translate.instant('MOBILE.lbl.no');
      } else if (err.field === 'email') {
        field = this.translate.instant('LBL.email');
      }
    }
    return field;
  }

  /**
   * Get row error detail
   * @param row Row data
   * @returns error
   */
  // getRowErrDetail(row: number): string {
  //   const errList = this.errorDetailList?.filter((errDetail: ImportError) => {
  //     return errDetail?.row === row ? true : false;
  //   });
  //   const emptyName = errList?.findIndex((errDetail: ImportError) => errDetail.field === 'name');
  //   const emptyPax = errList?.findIndex((errDetail: ImportError) => errDetail.field === 'pax' && errDetail.empty);
  //   const invalidPax = errList?.findIndex((errDetail: ImportError) => errDetail.field === 'pax' && errDetail.invalid);
  //   const invalidMobile = errList?.findIndex((errDetail: ImportError) => errDetail.field === 'mobile');

  //   if (emptyName !== -1 && emptyPax !== -1) {
  //     return this.translate.instant('IMPORT.err.row.empty_field_2', {
  //       field: this.translate.instant('GUEST.lbl.guest_name'),
  //       field2: this.translate.instant('GUEST.lbl.pax')
  //     });
  //   } else if (emptyName !== -1 && invalidPax !== -1) {
  //     return this.translate.instant('IMPORT.err.row.empty_and_invalid_field', {
  //       empty: this.translate.instant('GUEST.lbl.guest_name'),
  //       invalid: this.translate.instant('GUEST.lbl.pax')
  //     });
  //   } else if (emptyName !== -1) {
  //     return this.translate.instant('IMPORT.err.row.empty_field', { field: this.translate.instant('GUEST.lbl.guest_name') });
  //   } else if (emptyPax !== -1) {
  //     return this.translate.instant('IMPORT.err.row.empty_field', { field: this.translate.instant('GUEST.lbl.pax') });
  //   } else if (invalidPax !== -1) {
  //     return this.translate.instant('IMPORT.err.row.invalid_field', { field: this.translate.instant('GUEST.lbl.pax') });
  //   } else if (invalidMobile !== -1) {
  //     return this.translate.instant('IMPORT.err.row.invalid_field', { field: this.translate.instant('MOBILE.lbl.no') });
  //   } else {
  //     return this.translate.instant('IMPORT.err.row.err');
  //   }
  // }

  async openUrl(url: string) {
    this.linkService.openUrl(url);
  }

  checkSystemInvitedBy(value: string) {
    let invitedBy: SettingField = {
      custom: true,
      value,
    };
    let existed = false;
    if (value) {
      Object.keys(this.systemInvitedByList).forEach((lang: string) => {
        if (this.systemInvitedByList[lang]) {
          this.invitedByList.forEach((x: SettingField) => {
            if (x?.value) {
              if (!x?.custom) {
                if (this.systemInvitedByList[lang]?.[x.value] &&
                  this.systemInvitedByList[lang][x.value]?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                    invitedBy = x;
                    existed = true;
                }
              } else {
                if (x.value?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                  invitedBy = x;
                  existed = true;
                }
              }
            }
          });
          if (!existed) {
            const stdList = this.invitedByService.getStdInvitedByList();
            stdList.forEach((x: SettingField) => {
              if (x?.value) {
                if (!x?.custom) {
                  if (this.systemInvitedByList[lang]?.[x.value] &&
                    this.systemInvitedByList[lang][x.value]?.toLowerCase() === value?.toLowerCase()?.trim()) {
                      invitedBy = x;
                      existed = true;
                  }
                } else {
                  if (x.value?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                    invitedBy = x;
                    existed = true;
                  }
                }
              }
            });
          }
        }
      });
    }

    return { invitedBy, existed };
  }

  checkSystemCategory(value: string) {
    let category: SettingField = {
      custom: true,
      value,
    };
    let existed = false;
    if (value) {
      Object.keys(this.systemCategoryList).forEach((lang: string) => {
        if (this.systemCategoryList[lang]) {
          this.categoryList.forEach((x: SettingField) => {
            if (x?.value) {
              if (!x?.custom) {
                if (this.systemCategoryList[lang]?.[x.value] &&
                  this.systemCategoryList[lang][x.value]?.toLowerCase() === value?.toLowerCase()?.trim()) {
                    category = x;
                    existed = true;
                }
              } else {
                if (x.value?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                  category = x;
                  existed = true;
                }
              }
            }
          });
          if (!existed) {
            const stdList = this.categoryService.getStdCategoryList();
            stdList.forEach((x: SettingField) => {
              if (x?.value) {
                if (!x?.custom) {
                  if (this.systemCategoryList[lang]?.[x.value] &&
                    this.systemCategoryList[lang][x.value]?.toLowerCase() === value?.toLowerCase()?.trim()) {
                      category = x;
                      existed = true;
                  }
                } else {
                  if (x.value?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                    category = x;
                    existed = true;
                  }
                }
              }
            });
          }
        }
      });
    }

    return { category, existed };
  }

  checkSystemDietaryReq(value: string) {
    let dietaryReq: SettingField = {
      custom: true,
      value,
    };
    let existed = false;
    if (value) {
      Object.keys(this.systemDietaryReqList).forEach((lang: string) => {
        if (this.systemDietaryReqList[lang]) {
          this.dietaryReqList.forEach((x: SettingField) => {
            if (x?.value) {
              if (!x?.custom) {
                if (this.systemDietaryReqList[lang]?.[x.value] &&
                  this.systemDietaryReqList[lang][x.value]?.toLowerCase() === value?.toLowerCase()?.trim()) {
                    dietaryReq = x;
                    existed = true;
                }
              } else {
                if (x.value?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                  dietaryReq = x;
                  existed = true;
                }
              }
            }
          });
          if (!existed) {
            const stdList = this.dietaryReqService.getStdDietaryReqList();
            stdList.forEach((x: SettingField) => {
              if (x?.value) {
                if (!x?.custom) {
                  if (this.systemDietaryReqList[lang]?.[x.value] &&
                    this.systemDietaryReqList[lang][x.value]?.toLowerCase() === value?.toLowerCase()?.trim()) {
                      dietaryReq = x;
                      existed = true;
                  }
                } else {
                  if (x.value?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                    dietaryReq = x;
                    existed = true;
                  }
                }
              }
            });
          }
        }
      });
    }

    return { dietaryReq, existed };
  }

  checkSystemSpecialReq(value: string) {
    let specialReq: SettingField = {
      custom: true,
      value,
    };
    let existed = false;
    if (value) {
      Object.keys(this.systemSpecialReqList).forEach((lang: string) => {
        if (this.systemSpecialReqList[lang]) {
          this.dietaryReqList.forEach((x: SettingField) => {
            if (x?.value) {
              if (!x?.custom) {
                if (this.systemSpecialReqList[lang]?.[x.value] &&
                  this.systemSpecialReqList[lang][x.value]?.toLowerCase() === value?.toLowerCase()?.trim()) {
                    specialReq = x;
                    existed = true;
                }
              } else {
                if (x.value?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                  specialReq = x;
                  existed = true;
                }
              }
            }
          });
          if (!existed) {
            const stdList = this.specialReqService.getStdSpecialReqList();
            stdList.forEach((x: SettingField) => {
              if (x?.value) {
                if (!x?.custom) {
                  if (this.systemSpecialReqList[lang]?.[x.value] &&
                    this.systemSpecialReqList[lang][x.value]?.toLowerCase() === value?.toLowerCase()?.trim()) {
                      specialReq = x;
                      existed = true;
                  }
                } else {
                  if (x.value?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) {
                    specialReq = x;
                    existed = true;
                  }
                }
              }
            });
          }
        }
      });
    }

    return { specialReq, existed };
  }

  goHowImportLink() {
    if (this.languageService.getUserLanguage()?.code === 'zh' || this.languageService.getUserLanguage()?.code === 'zh-TW') {
      this.linkService.openUrl(HowImportUrl['zh-TW']);
    } else {
      this.linkService.openUrl(HowImportUrl.en);
    }
  }

  // convertBlobToBase64(blob: Blob): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result as string);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(blob); // Read the Blob as a data URL (Base64)
  //   });
  // }

}
