<ng-container *ngIf="guest?.guestId">
  <ion-item-sliding [disabled]="disableSlide" #slidingItem>
    <ion-item [class]="guest.groupId && showField?.group ? 'member-item' : ''" [color]="(selectedGuestList && selectedGuestList?.indexOf(guest?.guestId) !== -1) || selected ? 'tertiary' : 'white'" [lines]="disableLines ? 'none' : 'full'" [button]="disableClick ? false : true" [detail]="mode === 'invites'" (click)="guestClick(guest)">
      <ion-icon class="ion-no-margin font-10 group-member-icon" slot="start" src="./assets/wedding/icon/general/chevron-forward.svg" *ngIf="guest.groupId && showField.group"></ion-icon>
      <ng-container *ngIf="mode !== 'visitor'">
        <ng-container *ngIf="module === 'checkin' || module === 'gift' else statusBlock">
          <ion-text slot="start">
            <div>
              <ion-icon class="center" class="icon-large" src="./assets/wedding/icon/checkin/tick-round.svg" *ngIf="guest.status.checkin"></ion-icon>
              <ion-icon class="center" class="icon-large" src="./assets/wedding/icon/checkin/close-round.svg" *ngIf="!guest.status.checkin"></ion-icon>
            </div>
          </ion-text>
        </ng-container>
        <ng-template #statusBlock>
          <ng-container *ngIf="guest?.status?.attending && showField.attendingStatus">
            <ion-icon class="icon-large" slot="start" src="./assets/wedding/icon/guest/status/pending.svg" *ngIf="guest.status.attending === 'pending'"></ion-icon>
            <ion-icon class="icon-large" slot="start" src="./assets/wedding/icon/guest/status/attending.svg" *ngIf="guest.status.attending === 'attending'"></ion-icon>
            <ion-icon class="icon-large" slot="start" src="./assets/wedding/icon/guest/status/not-attending.svg" *ngIf="guest.status.attending === 'not_attending'"></ion-icon>
          </ng-container>
        </ng-template>
      </ng-container>
      
      <ion-label>
        <p class="ion-no-margin" *ngIf="guest.name">
          <ion-text class="font-14 font-bold">{{ guest.name }}</ion-text>
        </p>
        <p *ngIf="guest.nickname">
          <ion-text class="font-10">{{ guest.nickname }}</ion-text>
        </p>
        <p>
          <ion-text class="font-10">
            <ng-container *ngIf="showField.invitedBy">
              <ng-container *ngIf="invitedBy">
                {{ invitedBy }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="showField.invitedBy && showField.category && invitedBy && category">&nbsp;|&nbsp;</ng-container>
            <ng-container *ngIf="category && showField.category">
              {{ category }}
            </ng-container>
          </ion-text>
        </p>
        <ng-container>
          <p class="font-10" *ngIf="guest?.dietaryReq?.length && showField.dietaryReq && dietaryReq">
            <ng-container *ngIf="dietaryReq">
              <ion-icon class="font-10" src="./assets/wedding/icon/guest/dietary-req.svg"></ion-icon> {{ dietaryReq }}
            </ng-container>
          </p>
          <p class="font-10" *ngIf="guest?.specialReq?.length && showField?.specialReq && specialReq">
            <ng-container *ngIf="specialReq">
              <ion-icon class="font-10" src="./assets/wedding/icon/guest/special-req.svg"></ion-icon> {{ specialReq }}
            </ng-container>
          </p>
        </ng-container>
        <ng-container *ngIf="mode === 'invites'">
          <p class="font-10" *ngIf="guest?.rsvp?.createBy?.time?.seconds">
            {{ format(guest.rsvp.createBy.time.seconds * 1000) }}
          </p>
        </ng-container>
      </ion-label>
      <ion-text slot="end" *ngIf="module === 'guest' && mode !== 'visitor'">
        <div *ngIf="showField.seatingStatus">
          <ion-icon class="icon-small" src="./assets/wedding/icon/guest/seating-outline.svg" *ngIf="!guest.seating"></ion-icon>
          <ion-icon class="icon-small" src="./assets/wedding/icon/guest/seating-green.svg" *ngIf="guest.seating"></ion-icon>
        </div>
        <div *ngIf="showField.qrcodeStatus">
          <ion-icon class="icon-small" src="./assets/wedding/icon/qrcode/qrcode.svg" *ngIf="!guest.status.qrcode"></ion-icon>
          <ion-icon class="icon-small" src="./assets/wedding/icon/qrcode/qrcode-green.svg" *ngIf="guest.status.qrcode"></ion-icon>
        </div>
      </ion-text>
      <ion-text slot="end" *ngIf="module === 'gift' && guest?.giftList?.length">
        <div *ngIf="giftCount?.cash > 0"><ion-icon class="icon-small vertical-middle" src="./assets/wedding/icon/gift/cash.svg"></ion-icon></div>
        <div *ngIf="giftCount?.other > 0"><ion-icon class="icon-small vertical-middle" src="./assets/wedding/icon/gift/gift.svg"></ion-icon></div>
      </ion-text>
      <ion-text slot="end" class="ion-no-margin" *ngIf="showGuestSeating">
        <ion-card class="box-shadow seating-card ion-no-margin ion-text-center">
          <ion-item class="setting-item full-width ion-item-no-padding ion-text-center" lines="none">
            <ion-text class="ion-no-margin ion-text-center">
              <p class="ion-no-margin"><ion-text class="font-10">{{ seatingTypeName }}</ion-text></p>
              <!-- <fit-line [minFontSize]="10" [maxFontSize]="24" [calcOnResize]="true">
                <div class="text-ellipsis font-bold" style="width: 50px;">{{ guest?.seating ? guest.seating : '?' }}</div>
              </fit-line> -->
              <div class="vertical-center" style="overflow:hidden;" [style.width.px]="50" [style.height.px]="30">
                <div class="font-bold" [style.width.px]="50 - 10" [fittext]="true"
                [modelToWatch]="guest.seating"
                [maxFontSize]="24"
                [minFontSize]="8"
                [activateOnResize]="true">{{guest.seating}}</div>
              </div>
            </ion-text>
          </ion-item>
        </ion-card>
      </ion-text>
      <ion-text slot="end" *ngIf="module === 'blast' && blastUsageId && (mode === 'sms-result' || mode === 'whatsapp-result')">
        <div *ngIf="blastMsg">
          <div *ngIf="deliveredStatus">
            <ion-icon color="dark" src="./assets/wedding/icon/general/time-outline.svg" *ngIf="deliveredStatus === 'processing'"></ion-icon>
            <ion-icon color="dark" src="./assets/wedding/icon/general/checkmark-outline.svg" *ngIf="deliveredStatus === 'sent'"></ion-icon>
            <ion-icon color="dark" src="./assets/wedding/icon/general/checkmark-done-outline.svg" *ngIf="deliveredStatus === 'delivered'"></ion-icon>
            <ion-icon color="danger" src="./assets/wedding/icon/general/alert-circle.svg" *ngIf="deliveredStatus === 'failed'"></ion-icon>
            <ion-icon class="wa-read-icon" src="./assets/wedding/icon/general/checkmark-done-outline.svg" *ngIf="deliveredStatus === 'read'"></ion-icon>
          </div>
        </div>
      </ion-text>
      <!-- <ion-text class="font-12" slot="end" *ngIf="mode === 'invites' && guest?.status?.attending">
        {{ 'LIST.attending_status.' + guest.status.attending | translate }}
      </ion-text> -->
    </ion-item>
    <ion-item-options side="end">
      <ng-container *ngIf="module === 'guest'">
        <ion-item-option color="tertiary" (click)="presentGuestQrcodeModal(guest, slidingItem)" *ngIf="!disableQrcode"><ion-icon src="./assets/wedding/icon/qrcode/qrcode.svg"></ion-icon></ion-item-option>
        <ion-item-option color="primary" (click)="seating(guest, slidingItem)" *ngIf="!disableSeating"><ion-icon src="./assets/wedding/icon/guest/seating-outline.svg"></ion-icon></ion-item-option>
      </ng-container>
      <ng-container *ngIf="module == 'checkin'">
        <ion-item-option color="success" (click)="promptCheckin(guest, slidingItem)" *ngIf="!guest?.status?.checkin && !disableCheckin">
          <ion-text class="ion-padding-horizontal">
            <ion-icon class="checkin-btn-icon vertical-middle" src="./assets/wedding/icon/checkin/tick-round.svg"></ion-icon> <ion-text class="font-12">{{ 'MODULE.list.checkin' | translate }}</ion-text>
          </ion-text>
        </ion-item-option>
        <ion-item-option color="medium" (click)="promptUncheck(guest, slidingItem)" *ngIf="guest?.status?.checkin && !disableUncheck">
          <ion-text class="ion-padding-horizontal">
            <ion-icon class="checkin-btn-icon vertical-middle" src="./assets/wedding/icon/checkin/close-round.svg"></ion-icon> <ion-text class="font-12">{{ 'MODULE.action.checkin.uncheck' | translate }}</ion-text>
          </ion-text>
        </ion-item-option>
      </ng-container>
      <ion-item-option color="success" (click)="presentGuestEditModal(guest, slidingItem)" *ngIf="!disableEdit && module === 'guest' || module === 'blast'"><ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon></ion-item-option>
      <ion-item-option color="success" (click)="promptRestore(guest, slidingItem)" *ngIf="!disableRestore && module === 'trash'"><ion-icon name="arrow-undo-outline"></ion-icon></ion-item-option>
      <ion-item-option color="danger" (click)="deleteFunc(guest, slidingItem)" *ngIf="!disableDelete && (module === 'guest')"><ion-icon src="./assets/wedding/icon/general/trash.svg"></ion-icon></ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</ng-container>