import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { SettingField } from 'src/app/interfaces/database';
import { Mobile } from 'src/app/interfaces/general';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';

import { WebsiteDetail, WebsiteLink, WebsiteThemeColor } from 'src/app/interfaces/website';

import { getTime } from 'date-fns';

import { FunctionService } from 'src/app/services/general/function.service';

import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';

import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';


import { WebsiteFormService } from 'src/app/services/website/website-form.service';
import { SettingFieldType } from 'src/app/types/general';
import { AttendingType } from 'src/app/types/guest';
import { WebsiteFormStep } from 'src/app/types/website';

import { AccountInfo } from 'src/app/interfaces/account';
import { PopupService } from 'src/app/services/general/popup.service';
import { WebsiteThemeColorList } from 'src/app/commons/websiteThemeColor';
import { WebsiteThemeList } from 'src/app/commons/websiteTheme';
import { WebsiteThemeService } from 'src/app/services/website/website-theme.service';
import { WebsiteSettingFieldService } from 'src/app/services/website/website-setting-field.service';
import { WebsiteTypeService } from 'src/app/services/website/website-type.service';

@Component({
  selector: 'app-website-form',
  templateUrl: './website-form.component.html',
  styleUrls: ['./website-form.component.scss'],
})
export class WebsiteFormComponent  implements OnInit, OnDestroy {

  @Input() pageMode: boolean;

  @Input() previewMode: boolean;

  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;

    this.nameQuestion = this.getContent(this.websiteDetail?.form?.fields?.name);
    this.statusQuestion = this.getContent(this.websiteDetail?.form?.fields?.status);
    this.groupQuestion = this.getContent(this.websiteDetail?.form?.fields?.group);
    this.memberQuestion = this.getContent(this.websiteDetail?.form?.fields?.member);
    this.dietaryReqQuestion = this.getContent(this.websiteDetail?.form?.fields?.dietaryReq);
    this.specialReqQuestion = this.getContent(this.websiteDetail?.form?.fields?.specialReq);
    this.contactQuestion = this.getContent(this.websiteDetail?.form?.fields?.contact);
    this.accountTitle = this.getContent(this.websiteDetail?.info?.title);
    this.title = this.getContent(this.websiteDetail?.form?.title);
    this.msg = this.getContent(this.websiteDetail?.form?.msg);
    this.successMsg = this.getContent(this.websiteDetail?.form?.fields?.success);
    this.editMsg = this.getContent(this.websiteDetail?.form?.fields?.edit);
    this.summaryMsg = this.getContent(this.websiteDetail?.form?.fields?.summary);

    if (this.websiteDetail?.form?.maxGuest) {
      this.maxGuest = this.websiteDetail.form.maxGuest;
    } else {
      this.maxGuest = this.websiteFormService.getMaxGuest();
    }
  }

  @Input() set setWebsiteLink(websiteLink: WebsiteLink) {
    this.websiteLink = websiteLink;
  }

  @Input() websiteThemeColor: WebsiteThemeColor;

  @Input() accountInfo: AccountInfo;

  @Input() linkId: string;

  @Input() accountId: string;

  @Input() updateTheme: boolean;

  // @Input() guestId: string;

  // @Input() groupId: string;

  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.initialize();
    } else {
      // this.unwatchWebsiteForm();
      // this.unwatchAccountInfo();
      this.step = '';
      // this.websiteForm = null;
      this.counterFlag = false;
    }
  }

  @Output() goBack = new EventEmitter<boolean>();

  @Output() setGuest = new EventEmitter<Guest>();
  @Output() setGroup = new EventEmitter<Group>();
  @Output() setMemberList = new EventEmitter<Guest[]>;
  // @Output() goQrCodePage = new EventEmitter<string>();

  maxGuest: number;
  websiteDetail: WebsiteDetail;
  websiteLink: WebsiteLink;
  accountTitle: string;
  title: string;
  msg: string;
  successMsg: string;
  editMsg: string;
  summaryMsg: string;
  nameQuestion: string;
  statusQuestion: string;
  groupQuestion: string;
  memberQuestion: string;
  dietaryReqQuestion: string;
  specialReqQuestion: string;
  contactQuestion: string;

  anonymous: boolean;
  
  mobile: Mobile;

  mobileInvalid: boolean;
  
  isHybrid: boolean;

  step: WebsiteFormStep;

  editMode: boolean;

  otpForm: FormGroup;
  nameForm: FormGroup;
  contactForm: FormGroup;

  validationMsg: any;

  stopGif: boolean;

  @Input() guest: Guest;

  @Input() group: Group;

  @Input() oriMemberList: Guest[];
  @Input() memberList: Guest[];

  @Input() invitedByList: SettingField[];
  @Input() categoryList: SettingField[];

  @Input() dietaryReqList: SettingField[];
  @Input() specialReqList: SettingField[];

  @Input() mediaHeight: number;

  @Input() landscapeMode: boolean;

  

  attendingStatus: AttendingType;

  pax: number;

  newDietaryReq: { [index: number]: string };
  newSpecialReq: { [index: number]: string };

  newDietaryReqList: SettingField[];
  newSpecialReqList: SettingField[];

  selectedIndex = 0;

  webCheckinTitle: string;

  private deleteMemberList: string[];

  private settingFieldNone: SettingField = {
    custom: false,
    value: 'none'
  };

  private settingFieldOthers: SettingField = {
    custom: false,
    value: 'others'
  };

  days: number;
  hours: number;
  minutes: number;

  // statusFormTouched: boolean;

  promptErr: boolean;

  iosWeb: boolean;

  private invitedBy: SettingField;
  private category: SettingField;

  private timeout: any;

  private counterFlag: boolean;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private websiteFormService: WebsiteFormService,
    private websiteThemeService: WebsiteThemeService,
    private websiteSettingFieldService: WebsiteSettingFieldService,
    private websiteTypeService: WebsiteTypeService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private settingFieldService: SettingFieldService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.counterFlag = false;
  }

  ionViewWillEnter() {
    this.initialize();
  }

  ionViewWillLeave() {
    this.step = '';
    this.counterFlag = false;
  }

  initialize() {
    this.webCheckinTitle = this.getWebCheckinTitle();
    this.editMode = false;
    this.setupWebsiteThemeColor();

    this.memberList = this.functionService.cloneDeep(this.oriMemberList);

    if (this.platform.is('ios') && this.platform.is('mobileweb')) {
      this.iosWeb = true;
    } else {
      this.iosWeb = false;
    }

    if (!this.step) {
      if ((this.guest?.guestId || this.group?.groupId) && this.memberList?.length) {
        let rsvp = false;
        if (this.group?.groupId) {
          if (!this.group?.rsvp?.updateBy?.time) {
            rsvp = true;
          }
        }
        if (this.guest?.guestId) {
          if (!this.guest?.rsvp?.updateBy?.time) {
            rsvp = true;
          }
        }

        if (this.guest?.status?.attending) {
          this.attendingStatus = this.guest.status.attending;
        } else if (this.group?.groupId && this.memberList?.length) {
          const attendingStatusList = [...new Set(this.memberList.map((guest: Guest) => guest?.status?.attending))];
          this.attendingStatus = attendingStatusList?.length === 1 ? attendingStatusList[0] : '';
        } else {
          this.attendingStatus = '';
        }

        if (this.guest?.mobile?.no) {
          this.mobile = this.guest.mobile;
        } else if (this.group?.mobile?.no) {
          this.mobile = this.group.mobile;
        } else if (this.memberList?.length) {
          this.memberList.forEach((guest: Guest) => {
            if (guest?.mobile?.no) {
              this.mobile = guest.mobile;
            }
          });
        }

        if (this.memberList?.length) {
          this.pax = this.memberList.length;
        }

        if (this.guest?.status?.attending === 'pending' || this.group?.status?.attending === 'pending' || rsvp) {
          this.setupNameForm();
          this.step = 'name';
          this.editMode = true;
        } else {
          this.setupContactForm();
          this.step = 'summary';
        }

      } else {
        this.setupNameForm();
        this.step = 'name';
      }
      
      // if (this.previewMode) {
      //   this.setupNameForm();
      //   this.step = 'name';
      // } else {
      //   this.checkAuth();
      // }
    }

    if (!this.pax) {
      if (this.group?.groupId && this.memberList?.length) {
        this.pax = this.memberList.length;
      } else if (this.guest?.guestId) {
        this.pax = 1;
      }
      // else if (this.websiteLink?.maxGuest) {
      //   this.pax = this.websiteLink.maxGuest;
      // }
      this.pax = 1;
    }

    if (!this.invitedBy) {
      if (this.group?.groupId && this.memberList?.length) {
        this.invitedBy = this.memberList?.[0].invitedBy?.[0];
      } else if (this.guest?.guestId && this.guest?.invitedBy?.length) {
        this.invitedBy = this.guest.invitedBy[0];
      } else if (this.websiteLink?.invitedBy?.length) {
        this.invitedBy = this.websiteLink.invitedBy[0];
      } else if (this.invitedByList?.length === 1) {
        this.invitedBy = this.invitedByList[0];
      } else {
        if (this.invitedByList?.length) {
          const index = this.invitedByList.findIndex((x: SettingField) => {
            return x.custom === this.websiteSettingFieldService.settingFieldOnlineReg.custom && x.value === this.websiteSettingFieldService.settingFieldOnlineReg.value;
          });
          if (index === -1) {
            this.invitedBy = this.invitedByList[0];
          } else {
            this.invitedBy = this.websiteSettingFieldService.settingFieldOnlineReg;
          }
        } else {
          this.invitedBy = this.websiteSettingFieldService.settingFieldOnlineReg;
        }
      }
    }

    if (!this.category) {
      if (this.group?.groupId && this.memberList?.length) {
        this.category = this.memberList?.[0].category?.[0];
      } else if (this.guest?.guestId && this.guest?.category?.length) {
        this.category = this.guest.category[0];
      } else if (this.websiteLink?.category?.length) {
        this.category = this.websiteLink.category[0];
      } else if (this.categoryList?.length === 1) {
        this.category = this.categoryList[0];
      } else {
        if (this.categoryList?.length) {
          const index = this.categoryList.findIndex((x: SettingField) => {
            return x.custom === this.websiteSettingFieldService.settingFieldOnlineReg.custom && x.value === this.websiteSettingFieldService.settingFieldOnlineReg.value;
          });
          if (index === -1) {
            this.category = this.category[0];
          } else {
            this.category = this.websiteSettingFieldService.settingFieldOnlineReg;
          }
        } else {
          this.category = this.websiteSettingFieldService.settingFieldOnlineReg;
        }
      }
    }

    if (!this.memberList?.length) {
      this.memberList = [];
    }
    
    if (this.step === 'name') {
      this.setupNameForm();
    }

    if (this.previewMode) {
      if (!this.dietaryReqList?.length) {
        this.dietaryReqList = this.dietaryReqService.getDietaryReqList();
      }
      
      if (!this.specialReqList?.length) {
        this.specialReqList = this.specialReqService.getSpecialReqList();
      }
    }

    this.isHybrid = this.platform.is('hybrid');

    this.counterFlag = true;
    this.setupCountDown();
    this.counter();

    this.newDietaryReq = {};
    this.newSpecialReq = {};
    this.newDietaryReqList = [];
    this.newSpecialReqList = [];

    this.deleteMemberList = [];
  }

  setupWebsiteThemeColor() {
    if (!this.websiteThemeColor) {
      const websiteTheme = this.websiteDetail?.theme ? this.websiteDetail.theme : this.websiteThemeService.getWebsiteTheme();
      if (websiteTheme?.backgroundId) {
        const colorId = websiteTheme?.colorId;
        if (colorId && WebsiteThemeColorList?.[colorId]) {
          this.websiteThemeColor = WebsiteThemeColorList[colorId];
        } else if (WebsiteThemeList[websiteTheme.backgroundId]?.colorId?.length) {
          const colorId =  WebsiteThemeList[websiteTheme.backgroundId].colorId[0];
          if (colorId && WebsiteThemeColorList?.[colorId]) {
            this.websiteThemeColor = WebsiteThemeColorList[colorId];
          }
        }
      }
    }
  }

  setupFormValidation() {

  }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

  setupOtpForm() {
    this.otpForm = this.formBuilder.group({
      otp: new FormControl('', [ Validators.required ]),
    });
    
    this.validationMsg = {
      otp: [{ type: 'required', msg: this.websiteDetail?.form?.fields?.required ? this.websiteDetail.form.fields.required : this.translate.instant('VALIDATION.required', { field: this.translate.instant('MOBILE.lbl.otp') }) }],
    };
  }

  setupNameForm() {
    this.nameForm = this.formBuilder.group({
      name: new FormControl(this.guest?.name ? this.guest?.name : this.group?.groupName, [ Validators.required ]),
    });
    
    this.validationMsg = {
      name: [{ type: 'required', msg: this.websiteDetail?.form?.fields?.required ? this.websiteDetail.form.fields.required : this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.name') }) }],
    };
  }

  setupContactForm() {
    this.contactForm = this.formBuilder.group({
      email: new FormControl(this.guest?.email ? this.guest.email : this.group?.email, [ Validators.email, (this.websiteDetail?.form?.email?.type === 'required' ? Validators.required : Validators.nullValidator) ]),
    });
    
    if (!this.contactForm?.value?.email) {
      if (this.memberList?.length) {
        this.memberList.forEach((guest: Guest) => {
          if (!this.contactForm?.value?.email && guest?.email) {
            this.contactForm.controls.email.setValue(guest.email);
          }
        })
      }
    }

    this.validationMsg = {
      email: [
        { type: 'required', msg: this.websiteDetail?.form?.fields?.required ? this.websiteDetail.form.fields.required : this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.email') }) },
        { type: 'email', msg: this.websiteDetail?.form?.fields?.invalid ? this.websiteDetail.form.fields.invalid : this.translate.instant('VALIDATION.invalid_format', { field: this.translate.instant('LBL.email') }) }
      ],
    };
  }

  setupCountDown() {
    if (this.websiteDetail?.form?.cutOff?.enable) {
      if (this.accountInfo?.time?.seconds) {
        let date = (((this.accountInfo.time.seconds + (this.websiteDetail?.form?.cutOff?.time * 60)) * 1000) - getTime(new Date())) / 1000;
        if (date > 0) {
          this.days = Math.floor(date / 86400);
          date -= this.days * 86400;
  
          this.hours = Math.floor(date / 3600) % 24;
          date -= this.hours * 3600;
  
          this.minutes = Math.floor(date / 60) % 60;
          date -= this.minutes * 60;
        } else {
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
        }
      } else {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
      }
    } else {
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
    }
  }

  async counter() {
    await this.stopTimeout();
    this.timeout = setTimeout(() => {
      this.setupCountDown();
      if (this.counterFlag) {
        this.counter();
      }
    }, 1000);
  }

  async stopTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  back() {
    if (this.pageMode) {
      this.goBack.next(true);
    } else {
      this.dismissModal();
    }
  }

  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  addQty() {
    if (this.maxGuest && this.maxGuest > this.pax) {
      this.pax += 1;
      this.promptErr = false;
    } else {
      this.promptErr = true;
    }
  }

  minusQty() {
    this.pax -= 1;
    if (!this.pax && this.pax < 1) {
      this.pax = 1;
      this.promptErr = true;
    } else if (this.pax > this.maxGuest) {
      this.promptErr = true;
    } else {
      this.promptErr = false;
    }
  }

  qtyChanged() {
    if (this.maxGuest && this.pax > this.maxGuest) {
      this.promptErr = true;
    } else {
      this.promptErr = false;
    }
  }

  setAnonymous() {
    this.anonymous = true;
    this.next();
  }

  setAttendingStatus(attendingStatus: AttendingType) {
    this.attendingStatus = attendingStatus;
  }

  setDietaryReq(dietaryReq: SettingField, index?: number) {
    // this.dietaryReq = dietaryReq;
    if (!this.functionService.isUndefined(index) && this.memberList?.[index]) {
      this.memberList[index].dietaryReq = [ dietaryReq ];
    }
  }

  setSpecialReq(specialReq: SettingField, index?: number) {
    // this.specialReq = specialReq;
    if (!this.functionService.isUndefined(index) && this.memberList?.[index]) {
      this.memberList[index].specialReq = [ specialReq ];
    }
  }

  setOtpResult(result: boolean) {
    if (result) {
      this.next();
    }
  }

  updateMobile(mobile: Mobile) {
    this.mobile = mobile;
  }

  updateMobileInvalid(invalid: boolean) {
    this.mobileInvalid = invalid;
  }

  async checkAuth() {
    const mobile: Mobile = await this.websiteFormService.checkAuth();
    // const currentUser = await this.authService.getCurrentUser();
    // if (currentUser?.uid && currentUser?.providerData?.length) {
    //   currentUser.providerData.forEach((provider: Provider) => {
    //     if (provider?.providerId === 'phone' && provider?.phoneNumber) {
    //       mobile = this.mobileService.generate(provider.phoneNumber);
    //       if (mobile?.code && mobile?.no) {
                   
    //       }
    //     }
    //   });
    // }
    // this.mobile = mobile;

    if (this.mobile?.code && this.mobile?.no) {
      this.anonymous = false;
      this.setupNameForm();
      this.step = 'name';  
    } else {
      this.anonymous = true;
      this.setupOtpForm();
      this.step = 'otp';
    }
    // else if (currentUser?.uid) {
    //   this.anonymous = true;
    //   this.setupOtpForm();
    //   this.step = 'otp';
    // } 
  }

  checkSettingField(a: SettingField, b: SettingField) {
    return a?.value && b?.value && a?.value === b?.value && a?.custom === b?.custom;
  }

  checkDietaryReqOn() {
    if (!this.websiteDetail?.form?.dietaryReq?.enable || (this.dietaryReqList?.length <= 1 && !this.websiteDetail?.form?.dietaryReq?.others)) {
      return false;
    }
    return true;
  }

  checkSpecialReqOn() {
    if (!this.websiteDetail?.form?.specialReq?.enable || (this.specialReqList?.length <= 1 && !this.websiteDetail?.form?.specialReq?.others)) {
      return false;
    }
    return true;
  }

  checkGif() {
    this.stopGif = false;
    if (this.step === 'result') {
      setTimeout(() => {
        if (this.attendingStatus === 'attending') {
          if (this.websiteDetail?.qrcode?.enable) {
            this.goQrCode();
          } else if (this.websiteDetail?.checkin?.enable) {
            this.goCheckin();
          }
        } else {
          this.stopGif = true;
        }
      }, 4200);
    }
  }

  getDisabled(promptErr?: boolean) {
    if (promptErr) {
      this.promptErr = false;
    }
    
    if (this.step === 'otp') {
      return false;
    } else if (this.step === 'name') {
      this.nameForm.markAllAsTouched();
      if (this.nameForm?.valid) {
         return false;
      }
    } else if (this.step === 'status') {
      if (this.attendingStatus === 'attending' || this.attendingStatus === 'not_attending') {
        return false;
      } else if (promptErr) {
        this.promptErr = true;
      }
    } else if (this.step === 'group') {
      if (this.pax && this.pax > 0 && this.maxGuest && this.pax <= this.maxGuest) {
        return false;
      } else if (promptErr) {
        this.promptErr = true;
      }
    } else if (this.step === 'member') {
      let err = false;
      this.memberList?.forEach((guest: Guest) => {
        if (!guest?.name) {
          err = true;
        }
      });
      if (!err) {
        return false;
      } else if (promptErr) {
        this.promptErr = true;
      }
    } else if (this.step == 'dietaryReq') {
      let err = false;
      this.memberList?.forEach((guest: Guest, index: number) => {
        if (!guest?.dietaryReq?.[0]?.value) {
          err = true;
        } else if (this.checkSettingField(guest.dietaryReq[0], this.settingFieldOthers)) {
          if (!this.newDietaryReq?.[index]) {
            err = true;
          }
        }
      });
      if (!err) {
        return false;
      } else if (promptErr) {
        this.promptErr = true;
      }
    } else if (this.step === 'specialReq') {
      let err = false;
      this.memberList?.forEach((guest: Guest, index: number) => {
        if (!guest?.specialReq?.[0]?.value) {
          err = true;
        } else if (this.checkSettingField(guest.specialReq[0], this.settingFieldOthers)) {
          if (!this.newSpecialReq?.[index]) {
            err = true;
          }
        }
      });
      if (!err) {
        return false;
      } else if (promptErr) {
        this.promptErr = true;
      }
    } else if (this.step === 'contact') {
      this.contactForm.markAllAsTouched();
      if (this.contactForm?.valid 
        && (this.websiteDetail?.form?.email?.type !== 'required' || (this.contactForm?.value?.email && this.functionService.validateEmail(this.contactForm.value.email))) 
        && (!this.mobileInvalid && (this.attendingStatus === 'not_attending' || this.websiteDetail?.form?.mobile?.type !== 'required' || this.mobile?.no))
      ) {
        return false;
      } else if (promptErr) {
        this.promptErr = true;
      }
    } else if (this.step === 'summary') {
      return false;
    }
    return true;
  }

  getDietaryReq(index: number): SettingField {
    if (this.memberList?.[index]?.dietaryReq?.length) {
      return this.memberList[index].dietaryReq[0];
    }
    return this.settingFieldNone;
  }

  getSpecialReq(index: number): SettingField {
    if (this.memberList?.[index]?.specialReq?.length) {
      return this.memberList[index].specialReq[0];
    }
    return this.settingFieldNone;
  }

  getSettingField(settingFieldType: SettingFieldType, settingField: SettingField, index?: number) {
    if (!this.functionService.isUndefined(index)) {
      if (settingField?.value === 'others' && !settingField?.custom) {
        if (settingFieldType === 'dietary_req') {
          if (this.newDietaryReq?.[index]) {
            return this.newDietaryReq[index];
          }
        } else if (settingFieldType === 'special_req') {
          if (this.newSpecialReq?.[index]) {
            return this.newSpecialReq[index];
          }
        }
      }
    }
    return this.settingFieldService.getSettingField(settingFieldType, settingField);
  }

  getTitle() {
    return this.websiteDetail?.form?.title ? this.websiteDetail.form.title : this.translate.instant('WEBSITE.form.title');
  }

  getProgress() {
    let totalStep = 8;
    let currentStep = 0;
    if (this.step === 'name') {
      currentStep = 0;
    } else if (this.step === 'status') {
      currentStep = 1;
    } else if (this.step === 'group') {
      currentStep = 2;
    } else if (this.step === 'member') {
      currentStep = 3;
    } else if (this.step === 'dietaryReq') {
      currentStep = 4;
    } else if (this.step === 'specialReq') {
      currentStep = 5;
    } else if (this.step === 'contact') {
      currentStep = 6;
    } else if (this.step === 'summary') {
      currentStep = 7;
    } else if (this.step === 'result') {
      currentStep = 8;
    }

    if (currentStep > 1 && this.attendingStatus === 'not_attending') {
      totalStep -= 4;
      currentStep -= 4;
    } else {
      if (currentStep > 2 && this.pax === 1) {
        totalStep -= 1;
        currentStep -= 1;
      }
      if (!this.checkDietaryReqOn() || !this.dietaryReqList?.length || this.dietaryReqList.length === 1) {
        totalStep -= 1;
        if (currentStep > 3) {
          currentStep -= 1;
        }
      }
      if (!this.checkSpecialReqOn() || !this.specialReqList?.length || this.specialReqList.length === 1) {
        totalStep -= 1;
        if (currentStep > 3) {
          currentStep -= 1;
        }
      }
    }

    return currentStep / totalStep;
  }

  edit() {
    if (this.websiteDetail?.form?.edit && (!this.websiteDetail?.form?.cutOff?.enable || this.days || this.hours || this.minutes)) {
      this.step = 'name';
      this.setupNameForm();
      this.editMode = true;
    }
  }

  prev() {
    if (this.step === 'summary') {
      this.step = 'contact'
    } else if (this.step === 'contact') {
      if (this.attendingStatus === 'attending') {
        if (this.checkSpecialReqOn() && this.specialReqList?.length > 1) {
          this.step = 'specialReq';
        } else if (this.checkDietaryReqOn() && this.dietaryReqList?.length > 1) {
          this.selectedIndex = 0;
          this.step = 'dietaryReq';
        } else if (this.memberList?.length > 1) {
          this.step = 'member';
        } else if (this.maxGuest === 1) {
          this.step = 'status'
        } else {
          this.step = 'group';
        }
      } else {
        this.step = 'status';
      }
    } else if (this.step === 'specialReq') {
      if (this.checkDietaryReqOn()) {
        this.selectedIndex = 0;
        this.step = 'dietaryReq';
      } else if (this.memberList?.length > 1) {
        this.step = 'member';
      } else if (this.maxGuest === 1) {
        this.step = 'status'
      } else {
        this.step = 'group';
      }
    } else if (this.step === 'dietaryReq') {
      if (this.memberList?.length > 1) {
        this.step = 'member';
      } else if (this.maxGuest === 1) {
        this.step = 'status'
      } else {
        this.step = 'group';
      }
    } else if (this.step === 'member') {
      this.step = 'group';
    } else if (this.step === 'group') {
      this.step = 'status';
    } else if (this.step === 'status') {
      this.step = 'name';
    } else if (this.step === 'name') {
      if (this.editMode) {
        this.editMode = false;
        this.step = 'summary';
      } else {
        this.back();
      }
    }
  }

  next() {
    if (!this.getDisabled(true)) {
      if (this.step === 'otp') {
        this.setupNameForm();
        this.step = 'name';
      } else if (this.step === 'name') {
        this.nameForm.markAllAsTouched();
        if (this.nameForm?.valid) {
          this.step = 'status';
          if (this.guest?.status?.attending) {
            this.attendingStatus = this.guest.status.attending;
          } else if (this.group?.status?.attending) {
            this.attendingStatus = this.group.status.attending;
          }
        }
      } else if (this.step === 'status') {
        if (this.attendingStatus) {
          if (this.attendingStatus === 'attending') {
            this.step = 'group';
            if (this.group?.memberList?.length) {
              this.pax = this.group.memberList.length;
            }
          } else if (this.attendingStatus === 'not_attending') {
            this.setupContactForm();
            this.step = 'contact';
            if (this.guest?.mobile?.no) {
              this.mobile = this.guest.mobile;
            } else if (this.group?.mobile?.no) {
              this.mobile = this.group.mobile;
            }
          } else {
            // this.statusFormTouched = true;
          }
        } else {
          // this.statusFormTouched = true;
        }
      } else if (this.step === 'group') {
        if (this.pax && this.pax > 0 && this.maxGuest && this.pax <= this.maxGuest) {
          this.generateMemberList();
          if (this.pax > 1) {
            this.step = 'member';
          } else {
            this.selectedIndex = 0;
            this.step = 'dietaryReq';
          }
        }
      } else if (this.step === 'member') {
        this.selectedIndex = 0;
        this.step = 'dietaryReq';
      } else if (this.step === 'dietaryReq') {
        this.selectedIndex = 0;
        this.step = 'specialReq';
      } else if (this.step === 'specialReq') {
        this.setupContactForm();
        this.step = 'contact';

        if (this.guest?.mobile?.no) {
          this.mobile = this.guest.mobile;
        } else if (this.group?.mobile?.no) {
          this.mobile = this.group.mobile;
        }
      } else if (this.step === 'contact') {
        if (!this.memberList?.length) {
          this.generateMemberList();
        }
        this.step = 'summary';
      } else if (this.step === 'summary') {
        this.submit();
      }

      if (this.step === 'group') {
        if (this.maxGuest === 1) {
          this.pax = 1;
          this.selectedIndex = 0;
          this.step = 'dietaryReq';
        }
      }

      if (this.step === 'dietaryReq') {
        if (!this.checkDietaryReqOn() || !this.dietaryReqList?.length || this.dietaryReqList.length === 1) {
          this.selectedIndex = 0;
          this.step = 'specialReq';
        }
      }

      if (this.step === 'specialReq') {
        if (!this.checkSpecialReqOn() || !this.specialReqList?.length || this.specialReqList.length === 1) {
          this.setupContactForm();
          this.step = 'contact';
          if (this.guest?.mobile?.no) {
            this.mobile = this.guest.mobile;
          } else if (this.group?.mobile?.no) {
            this.mobile = this.group.mobile;
          }
        }
      }
      this.scrollToForm();
    }
  }

  generateMemberList() {
    if (this.memberList.length < this.pax) {
      const count = this.pax - this.memberList.length;
      for (let i = 0; i < count; i++) {
        const mobile: Mobile = {
          code: 0,
          country: '',
          no: '',
        };
        const name = this.nameForm.value.name;
        const invitedBy = [ this.invitedBy?.value ? this.invitedBy : this.websiteSettingFieldService.settingFieldOnlineReg ];
        const category = [ this.category?.value ? this.category : this.websiteSettingFieldService.settingFieldOnlineReg ];
        const dietaryReq = [ this.checkDietaryReqOn() && this.dietaryReqList?.length === 1 ? this.dietaryReqList[0] : this.settingFieldNone ];
        const specialReq = [ this.checkSpecialReqOn() && this.specialReqList?.length === 1 ? this.specialReqList[0] : this.settingFieldNone ];
        const attending = this.attendingStatus;

        if (this.oriMemberList?.[this.memberList.length + i]?.guestId) {
          const guest = this.functionService.cloneDeep(this.oriMemberList[this.memberList.length + i]);
          guest.name = name;
          guest.invitedBy = invitedBy;
          guest.category = category;
          guest.dietaryReq = dietaryReq;
          guest.specialReq = specialReq;
          guest.status.attending = attending;
          this.memberList.push(guest);

          const index = this.deleteMemberList?.indexOf(guest?.guestId);
          if (index !== -1) {
            this.deleteMemberList.splice(index, 1);
          }
        } else {
          const guest = {
            guestId: '',
            name,
            nickname: '',
            invitedBy,
            category,
            dietaryReq,
            session: [ this.settingFieldNone ],
            specialReq,
            groupId: '',
            seating: '',
            mobile,
            email: '',
            remark: '',
            giftList: [],
            status: {
              attending,
              checkin: false,
              gift: false,
              deleted: false,
              rsvp: true,
              qrcode: false
            }
          };
          this.memberList.push(guest);
        }
      }
    } else if (this.memberList.length > this.pax) {
      const count = this.memberList.length - this.pax;
      for (let i = 0; i < count; i++) {
        const guestId = this.memberList[this.memberList.length - 1]?.guestId;
        if (guestId && this.deleteMemberList?.indexOf(guestId) === -1) {
          this.deleteMemberList.push(guestId);
        }
        this.memberList?.splice(this.memberList.length - 1, 1);
      }
    }
  }

  setupGuestDietaryReq() {
    if (this.websiteDetail?.form?.dietaryReq?.others) {
      this.memberList.forEach((guest: Guest, index: number) => {
        if (guest?.dietaryReq?.[0]?.value === 'others' && !guest?.dietaryReq[0].custom) {
          if (this.newDietaryReq?.[index]) {
            const dietaryReq = {
              value: this.newDietaryReq[index],
              custom: true,
            }
            guest.dietaryReq[0] = dietaryReq;

            const newDietaryReqIndex = this.newDietaryReqList?.findIndex((x: SettingField) => {
              return x.custom === dietaryReq.custom && x.value === dietaryReq.value;
            });
            if (newDietaryReqIndex === -1) {
              this.newDietaryReqList.push(dietaryReq);
            }
          }
        }
      });
    }
  }

  setupGuestSpecialReq() {
    if (this.websiteDetail?.form?.specialReq?.others) {
      this.memberList.forEach((guest: Guest, index: number) => {
        if (guest?.specialReq?.[0]?.value === 'others' && !guest?.specialReq[0].custom) {
          if (this.newSpecialReq?.[index]) {
            const specialReq = {
              value: this.newSpecialReq[index],
              custom: true,
            };
            guest.specialReq[0] = specialReq;

            const newSpecialReqIndex = this.newSpecialReqList?.findIndex((x: SettingField) => {
              return x.custom === specialReq.custom && x.value === specialReq.value;
            });
            if (newSpecialReqIndex === -1) {
              this.newSpecialReqList.push(specialReq);
            }
          }
        }
      });
    }
  }

  setupGuestContact() {
    const mobile = this.mobile;
    const email = this.contactForm?.value?.email ? this.contactForm.value.email : '';

    this.memberList.forEach((guest: Guest, index: number) => {
      guest.mobile = mobile;
      guest.email = email;
    });
  }

  async submit() {
    await this.genereateGuest();
    
  }

  async genereateGuest() {
    if (this.memberList?.length) {
      const mobile = this.mobile;
      const email = this.contactForm?.value?.email ? this.contactForm.value.email : '';

      const accountId = this.accountId;
      this.setupGuestDietaryReq();
      this.setupGuestSpecialReq();
      this.setupGuestContact();
      
      if (this.memberList.length > 1) {
        const group: Group = this.group;
        if (!group?.groupId) {
          group.groupId = '';
        }
        group.groupName = this.nameForm.value.name;
        group.mobile = mobile;
        group.email = email;

        if (!group?.status) {
          group.status = {
            checkin: false,
            gift: false,
            deleted: false,
            rsvp: true,
            qrcode: false
          };
        }

        // group.status.attending = this.attendingStatus
        const result = await this.websiteFormService.createWebsiteGuest(accountId, this.linkId, this.memberList, group, [], [], this.deleteMemberList);
        this.setupData(result?.guestList, result?.group);
      } else {
        if (this.group?.groupId) {
          this.memberList = this.memberList.map((guest: Guest) => {
            if (guest?.groupId) {
              guest.groupId = '';
            }
            return guest;
          });
        }
        const result = await this.websiteFormService.createWebsiteGuest(accountId, this.linkId, this.memberList, null, [], [], this.deleteMemberList);
        this.setupData(result?.guestList, result?.group);
      }
    }
  }

  setupData(memberList?: Guest[], group?: Group) {
    let result = false;
    if (memberList?.length) {
      this.memberList = memberList;
      if (memberList?.length === 1) {
        this.guest = this.memberList[0];
        result = true;
      } else if (group) {
        this.group = group;
        result = true;
      } else {

      }
    }

    if (result) {
      const url: string = `/website/${ this.accountId }/0/${ this.guest?.guestId ? this.guest.guestId : 0 }/${ this.group?.groupId ? this.group.groupId : 0}/`;
      window.history.replaceState(null, '', url);

      this.step = 'result';
      
      this.setGuest.next(this.guest);
      this.setGroup.next(this.group);
      this.setMemberList.next(this.memberList);

      this.checkGif();
      // if (this.attendingStatus === 'attending') {
      //   setTimeout(() => {
      //     this.goQrCode();
      //   }, 5000);
      // }
    } else {
      this.popupService.presentAlert(this.translate.instant('MSG.action_error'));
    }
  }

  goQrCode() {
    this.websiteTypeService.setupWebsiteType('qrcode');
    // this.websiteTypeService.setupWebsiteType('qrcode');
    // ${ this.linkId ? this.linkId : 0 }
    // const url: string = `/website/${ this.accountId }/0/${ this.guest?.guestId ? this.guest.guestId : 0 }/${ this.group?.groupId ? this.group.groupId : 0}/qrcode`;
    // this.goQrCodePage.next(url);
  }

  goCheckin() {
    this.websiteTypeService.setupWebsiteType('checkin');
  }

  goHomePage() {
    this.websiteTypeService.setupWebsiteType('home');
  }

  scrollToForm() {
    const target = document.getElementById('form-div');
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollToInput(event: FocusEvent) {
    const target = event.target as HTMLElement;
    setTimeout(() => {
      target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300); // Delay to ensure keyboard is shown
  }

  getWebCheckinTitle() {
    if (this.websiteDetail?.checkin?.title) {
      return this.functionService.sanitizeHtml(this.websiteDetail.checkin.title);
    }
    return this.translate.instant('WEBSITE.checkin.title');
  }
}
