<div class="ion-page" *ngIf="showLoading">
  <app-loading class="full-width-height" [setReady]="ready"></app-loading>
</div>

<div id="website-page-div" class="font" [class]="(pdfMode ? 'full-width-height' : 'ion-page') + ' ' + (mobileMode ? 'mobile-mode': '')" [style.--backgroundLine]="websiteThemeColor?.backgroundLine" [style.--backgroundColor]="websiteThemeColor?.background" [style.--fontColor]="websiteThemeColor?.font" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" [style.--font]="websiteTheme?.font" *ngIf="!showLoading">
  <ion-menu side="start" swipeGesture="false" [type]="mobileMode ? 'overlay' : 'reveal'" [menuId]="designMode ? 'design-website-menu' : previewMode ? 'preview-website-menu' : 'website-menu'" [contentId]="designMode ? 'design-website-content' : previewMode ? 'preview-website-content' : 'website-content'" *ngIf="!pdfMode">
    <ion-header>
      <ion-toolbar [style.--background]="websiteThemeColor?.background ? websiteThemeColor.background : ''">
        <ion-buttons slot="start">
          <ion-button fill="clear" (click)="setType('home')">
            <ion-icon class="font-30" slot="icon-only" [src]="websiteType === 'home' ? './assets/wedding/icon/general/home.svg' : './assets/wedding/icon/general/home-outline.svg'"></ion-icon>
          </ion-button>
        </ion-buttons>
        <!-- <ion-title *ngIf="guest?.guestId || group?.groupId">
          <ion-button fill="clear" (click)="setType('profile')">
            <ion-icon class="font-30" slot="icon-only" [src]="websiteType === 'profile' ? './assets/wedding/icon/general/person.svg' : './assets/wedding/icon/general/person-outline.svg'"></ion-icon>
          </ion-button>
        </ion-title> -->
        <ion-buttons slot="end">
          <ion-button  fill="clear" (click)="closeMenu()">
            <ion-icon slot="icon-only" src="./assets/wedding/icon/general/close.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content [style.--background]="websiteThemeColor?.background ? websiteThemeColor.background : ''">
      <ion-list>
        <!-- <ion-icon class="edit-icon font-18" src="./assets/wedding/icon/general/edit.svg" *ngIf="(guestId || groupId) && websiteDetail?.form?.edit"></ion-icon> -->
        <ion-item class="background-transparent ion-text-center" [class]="websiteType === 'home' ? 'website-selected-menu-item' : ''" (click)="setType('home')" *ngIf="btn?.home" button detail>{{ btn.home }}</ion-item>
        <ion-item class="background-transparent ion-text-center" [class]="websiteType === 'profile' ? 'website-selected-menu-item' : ''" (click)="setType('profile')" *ngIf="btn?.profile && (guestId || groupId)" button detail>{{ btn.profile }}</ion-item>
        <ion-item class="background-transparent ion-text-center" [class]="websiteType === 'qrcode' ? 'website-selected-menu-item' : ''" (click)="setType('qrcode')" *ngIf="websiteDetail?.qrcode?.enable && btn?.qrcode && attendingStatus !== 'not_attending' && (guestId || groupId)" button detail>{{ btn.qrcode }}</ion-item>
        <ion-item class="background-transparent ion-text-center" [class]="websiteType === 'checkin' ? 'website-selected-menu-item' : ''" (click)="setType('checkin')" *ngIf="websiteDetail?.checkin?.enable && btn?.checkin && attendingStatus !== 'not_attending' && (guestId || groupId)" button detail>{{ btn.checkin }}</ion-item>
        <ion-item class="background-transparent ion-text-center" [class]="websiteType === 'rsvp' ? 'website-selected-menu-item' : ''" (click)="setType('rsvp')" *ngIf="websiteDetail?.form?.enable && (linkId || previewMode || guestId || groupId)" button detail>{{ (guestId || groupId) ? btn.rsvpTitle : btn.rsvpBtn }}</ion-item>
        <ion-item class="background-transparent ion-text-center" [class]="websiteType === 'venue' ? 'website-selected-menu-item' : ''" (click)="setType('venue')" *ngIf="websiteDetail?.venue?.enable && btn?.venue" button detail>{{ btn.venue }}</ion-item>
        <ion-item class="background-transparent ion-text-center" [class]="websiteType === 'contact' ? 'website-selected-menu-item' : ''" (click)="setType('contact')" *ngIf="websiteDetail?.contact?.enable && btn?.contact" button detail>{{ btn.contact }}</ion-item>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-toolbar [style.--background]="websiteThemeColor?.background ? websiteThemeColor.background : ''">
        <ion-text class="font-12" (click)="openWebsite()" [innerHTML]="'WEBSITE.powered' | translate: { year: currentYear }"></ion-text>
      </ion-toolbar>
    </ion-footer>
  </ion-menu>
  
  <div class="" [id]="designMode ? 'design-website-content' : previewMode ? 'preview-website-content' : 'website-content'" [class]="mobileMode ? 'mobile-mode-content' : ''" [ngClass]="[ !pdfMode ? (pageMode ? 'full-screen-width' : 'full-width-height') : '' ]" #mainElement>
    <div [class]="(pdfMode ? 'pdf-mode-container' : 'container')" [style.background-color]="websiteThemeColor?.background ? websiteThemeColor.background : ''" [style.height]="pdfMode ? 'auto' : '100%'">
      <ng-container *ngIf="!pdfMode && (websiteTheme?.cover?.media?.url ? websiteTheme.cover.media.url : websiteDetail?.theme?.cover?.media?.url) as cover">
        <div class="cover-photo cover-photo-landscape" [style.background-image]="'url(' + cover + ')'" [style.height]="'100%'" #coverPhotoLandscape>
          <ng-container *ngIf="true then coverTextBlock"></ng-container>
        </div>
      </ng-container>
  
      <div id="website-background-div" class="background" [class]="'background-' + websiteTheme?.backgroundSize + ' ' + (pdfMode ? 'pdf-mode-content' : 'content')" [style.background-image]="websiteTheme?.backgroundSize === 'cover' && backgroundUrl?.full ? 'url(' + backgroundUrl.full + ')' : ''">
        <ion-button class="floating-button" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="openMenu()" *ngIf="!pdfMode">
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/menu.svg"></ion-icon>
        </ion-button>
        
        <ng-container *ngIf="websiteType === 'home'">
          <ng-container *ngIf="!pdfMode && (websiteTheme?.cover?.media?.url ? websiteTheme.cover.media.url : websiteDetail?.theme?.cover?.media?.url) as cover">
            <div class="cover-photo cover-photo-portrait" [style.background-image]="'url(' + cover + ')'" [style.height]="'100%'" #coverPhotoPortrait>
              <ng-container *ngIf="true then coverTextBlock"></ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="true then frameTopBlock"></ng-container>

          <div class="full-width vertical-center" [class]="'background-div-' + websiteTheme?.backgroundSize + ' ' + (!websiteTheme?.backgroundSize ? 'ion-padding-vertical' : '')" [ngStyle]="{ 'min-height.px': pdfMode ? 0 : height - frameTopHeight - frameBottomHeight }" [style.background-image]="websiteTheme?.backgroundSize === 'scroll' && backgroundUrl?.full ? 'url(' + backgroundUrl.full + ')' : ''" #homeContentElement>
            <div class="full-width" [class]="websiteTheme?.opacity > 0 ? 'content-frame' : 'full-width'" [style.background-color]="websiteTheme?.opacity > 0 ? (websiteTheme?.opacity > 0 ? (websiteThemeColor?.contentOpacityColor + opacityToHex(websiteTheme.opacity)) : '') : ''">
              <ng-container *ngIf="contentsList?.length">
                <ng-container *ngFor="let content of contentsList">
                  <ng-container>
                    <ng-container *ngIf="content?.contentId && (!content?.custom || content?.enable)">
                      <ng-container *ngIf="content.contentId === 'time'">
                        <ng-container *ngIf="accountInfo?.time?.seconds && accountInfo?.timezone?.label">
                          <div class="full-width ion-padding-horizontal" *ngIf="websiteDetail?.time?.countDown">
                            <div class="count-down-div full-width" [style.background-color]="websiteThemeColor?.countDown?.background + opacityToHex(websiteThemeColor?.countDown?.opacity)">
                              <ng-container *ngIf="true then countDownBlock"></ng-container>
                            </div>
                          </div>
                          <div class="full-width ion-margin-vertical ion-padding-vertical">
                            <ng-container *ngIf="true then timeBlock"></ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'venue'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.venue?.enable">
                          <ng-container *ngIf="true then venueBlock"></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'gallery'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.gallery?.enable">
                          <ng-container *ngIf="true then galleryBlock"></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'save_the_date'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.form?.enable && (linkId || previewMode || guestId || groupId) && ((!websiteDetail?.form?.cutOff?.enable && timestamp && accountInfo?.time?.seconds && (accountInfo.time.seconds > (timestamp / 1000))) || (!actualDay && ((accountInfo.time.seconds + (websiteDetail?.form?.cutOff?.time * 60)) > (timestamp / 1000))))">
                          <ng-container *ngIf="true then rsvpBtnBlock"></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'qrcode'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.qrcode?.enable && attendingStatus !== 'not_attending' && (guestId || groupId)">
                          <ng-container *ngIf="websiteDetail?.qrcode?.displayType === 'button' then qrcodeBtnBlock else qrcodeEmbededBlock"></ng-container>
                          <ng-template #qrcodeEmbededBlock>
                            <ng-container *ngIf="true then qrcodeBlock"></ng-container>
                          </ng-template>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'web_checkin'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.checkin?.enable && attendingStatus !== 'not_attending' && (guestId || groupId) && (!checkinStatus || !websiteDetail?.qrcode?.enable)">
                          <ng-container *ngIf="websiteDetail?.checkin?.displayType === 'button' then webCheckinBtnBlock else webCheckinEmbededBlock"></ng-container>
                          <ng-template #webCheckinEmbededBlock>
                            <ng-container *ngIf="true then checkinBlock"></ng-container>
                          </ng-template>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'host_msg'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.hostMsg?.enable">
                          <ng-container *ngIf="true then hostMsgBlock"></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'itinerary'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.itinerary?.enable">
                          <ng-container *ngIf="true then itineraryBlock"></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'dress_code'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.dressCode?.enable">
                          <ng-container *ngIf="true then dressCodeBlock"></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'contact'">
                        <div class="full-width ion-margin-vertical ion-padding-vertical" *ngIf="websiteDetail?.contact?.enable">
                          <ng-container *ngIf="true then contactBlock"></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId === 'profile'">
                        <div class="full-width ion-margin-vertical" *ngIf="guest?.guestId || group?.groupId">
                          <ng-container *ngIf="true then profileBtnBlock"></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="content.contentId.indexOf('custom_') !== -1">
                        <div class="full-width">
                          <quill-view [content]="content.title" *ngIf="msg?.[content.contentId]?.title"></quill-view>
                          <ng-container *ngTemplateOutlet="mediaBlock; context: { media: content?.media }"></ng-container>
                          <quill-view [content]="content.msg" *ngIf="msg?.[content.contentId]?.msg"></quill-view>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="true then frameBottomBlock"></ng-container>
        </ng-container>
  
        <ng-container *ngIf="websiteType === 'rsvp'">
          <ng-container *ngIf="true then frameTopBlock"></ng-container>

          <div class="full-width vertical-center" [class]="'background-div-' + websiteTheme?.backgroundSize" [ngStyle]="{ 'min-height.px': height - frameTopHeight - frameBottomHeight }" [style.background-image]="websiteTheme?.backgroundSize === 'scroll' && backgroundUrl?.full ? 'url(' + backgroundUrl.full + ')' : ''">
            <div class="full-width" [class]="websiteTheme?.opacity > 0 ? 'content-frame' : ''" [style.background-color]="websiteTheme?.opacity > 0 ? (websiteThemeColor?.contentOpacityColor + opacityToHex(websiteTheme.opacity)) : ''">
              <ng-container *ngIf="true then rsvpFormBlock"></ng-container>
            </div>
          </div>

          <ng-container *ngIf="true then frameBottomBlock"></ng-container>
        </ng-container>
  
        <ng-container *ngIf="websiteType === 'qrcode'">
          <ng-container *ngIf="true then frameTopBlock"></ng-container>

          <div class="full-width vertical-center" [class]="'background-div-' + websiteTheme?.backgroundSize" [ngStyle]="{ 'min-height.px': pdfMode ? 0 : (height - frameTopHeight - frameBottomHeight) }" [style.background-image]="websiteTheme?.backgroundSize === 'scroll' && backgroundUrl?.full ? 'url(' + backgroundUrl.full + ')' : ''">
            <div class="full-width" [class]="websiteTheme?.opacity > 0 ? 'content-frame' : ''" [style.background-color]="websiteTheme?.opacity > 0 ? (websiteThemeColor?.contentOpacityColor + opacityToHex(websiteTheme.opacity)) : ''">
              <ng-container *ngIf="true then qrcodeBlock"></ng-container>
            </div>
          </div>
          
          <ng-container *ngIf="true then frameBottomBlock"></ng-container>
        </ng-container>
  
        <ng-container *ngIf="websiteType === 'venue'">
          <ng-container *ngIf="true then frameTopBlock"></ng-container>

          <div class="full-width vertical-center" [class]="'background-div-' + websiteTheme?.backgroundSize" [ngStyle]="{ 'min-height.px': height - frameTopHeight - frameBottomHeight }" [style.background-image]="websiteTheme?.backgroundSize === 'scroll' && backgroundUrl?.full ? 'url(' + backgroundUrl.full + ')' : ''">
            <div class="full-width" [class]="websiteTheme?.opacity > 0 ? 'content-frame' : ''" [style.background-color]="websiteTheme?.opacity > 0 ? (websiteThemeColor?.contentOpacityColor + opacityToHex(websiteTheme.opacity)) : ''">
              <ng-container *ngIf="true then venueBlock"></ng-container>
            </div>
          </div>
          
          <ng-container *ngIf="true then frameBottomBlock"></ng-container>
        </ng-container>
  
        <ng-container *ngIf="websiteType === 'checkin'">
          <ng-container *ngIf="true then frameTopBlock"></ng-container>

          <div class="full-width vertical-center" [class]="'background-div-' + websiteTheme?.backgroundSize" [ngStyle]="{ 'min-height.px': height - frameTopHeight - frameBottomHeight }" [style.background-image]="websiteTheme?.backgroundSize === 'scroll' && backgroundUrl?.full ? 'url(' + backgroundUrl.full + ')' : ''">
            <div class="full-width" [class]="websiteTheme?.opacity > 0 ? 'content-frame' : ''" [style.background-color]="websiteTheme?.opacity > 0 ? (websiteThemeColor?.contentOpacityColor + opacityToHex(websiteTheme.opacity)) : ''">
              <ng-container *ngIf="true then checkinBlock"></ng-container>
            </div>
          </div>
          
          <ng-container *ngIf="true then frameBottomBlock"></ng-container>
        </ng-container>
  
        <ng-container *ngIf="websiteType === 'contact'">
          <ng-container *ngIf="true then frameTopBlock"></ng-container>

          <div class="full-width vertical-center" [class]="'background-div-' + websiteTheme?.backgroundSize" [ngStyle]="{ 'min-height.px': height - frameTopHeight - frameBottomHeight }" [style.background-image]="websiteTheme?.backgroundSize === 'scroll' && backgroundUrl?.full ? 'url(' + backgroundUrl.full + ')' : ''">
            <div class="full-width" [class]="websiteTheme?.opacity > 0 ? 'content-frame' : ''" [style.background-color]="websiteTheme?.opacity > 0 ? (websiteThemeColor?.contentOpacityColor + opacityToHex(websiteTheme.opacity)) : ''">
              <ng-container *ngIf="true then contactBlock"></ng-container>
            </div>
          </div>
          
          <ng-container *ngIf="true then frameBottomBlock"></ng-container>
        </ng-container>

        <ng-container *ngIf="websiteType === 'profile'">
          <ng-container *ngIf="true then frameTopBlock"></ng-container>
          <div class="full-width vertical-center" [class]="'background-div-' + websiteTheme?.backgroundSize" [ngStyle]="{ 'min-height.px': height - frameTopHeight - frameBottomHeight }" [style.background-image]="websiteTheme?.backgroundSize === 'scroll' && backgroundUrl?.full ? 'url(' + backgroundUrl.full + ')' : ''">
            <div class="full-width" [class]="websiteTheme?.opacity > 0 ? 'content-frame' : ''" [style.background-color]="websiteTheme?.opacity > 0 ? (websiteThemeColor?.contentOpacityColor + opacityToHex(websiteTheme.opacity)) : ''">
              <ng-container *ngIf="guest?.guestId || group?.groupId then guestProfileBlock"></ng-container>
            </div>
          </div>
          <ng-container *ngIf="true then frameBottomBlock"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #coverTextBlock>
  <div class="cover-text-div">
    <div class="cover-text" [class]="[websiteTheme?.cover?.text?.position ? 'cover-text-' + websiteTheme.cover.text.position : '']" [style.background-color]="(websiteThemeColor?.background + opacityToHex(websiteTheme?.cover?.text?.opacity))">
      <div class="cover-title">
        <ng-container *ngIf="true then titleBlock"></ng-container>
      </div>
      <ng-container *ngIf="msg?.msg">
        <div class="cover-msg">
          <quill-view [content]="msg.msg"></quill-view>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #titleBlock>
  <ng-container *ngIf="msg?.title">
    <quill-view [content]="msg.title"></quill-view>
  </ng-container>
</ng-template>

<ng-template #timeBlock>
  <app-website-time class="full-width" [pageMode]="true" [previewMode]="previewMode ? true : false" [setAccountInfo]="accountInfo" [setStartTime]="accountInfo?.time?.seconds" [setEndTime]="accountInfo?.endTime?.seconds" [setWebsiteDetail]="websiteDetail" [websiteThemeColor]="websiteThemeColor" [setLocale]="accountInfo?.locale" [setDateFormat]="accountInfo?.dateFormat" [setTimeFormat]="accountInfo?.timeFormat" [setTimezone]="accountInfo?.timezone" [setFontSize]="websiteDetail?.time?.fontSize" [setDesign]="websiteDetail?.time?.design"></app-website-time>
</ng-template>

<ng-template #rsvpBtnBlock>
  <div class="ion-padding-horizontal ion-margin-horizontal">
    <ion-button class="center" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="toggleRsvpMode()">
      {{ (guestId || groupId) ? btn.rsvpTitle : btn.rsvpBtn }} <ion-icon class="edit-icon font-16" src="./assets/wedding/icon/general/edit.svg" *ngIf="(guestId || groupId) && websiteDetail?.form?.edit"></ion-icon>
    </ion-button>
  </div>
</ng-template>

<ng-template #qrcodeBtnBlock>
  <div class="ion-padding-horizontal ion-margin-horizontal" *ngIf="btn?.qrcode">
    <ion-button class="center" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="toggleQrcodeMode()">
      {{ btn.qrcode }}
    </ion-button>
  </div>
</ng-template>

<ng-template #webCheckinBtnBlock>
  <div class="ion-padding-horizontal ion-margin-horizontal" *ngIf="btn?.checkin">
    <ion-button class="center" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="toggleWebCheckinMode()">
      {{ btn.checkin }}
    </ion-button>
  </div>
</ng-template>

<ng-template #profileBtnBlock>
  <div class="ion-padding-horizontal ion-margin-horizontal ion-text-center" *ngIf="btn?.profile">
    <ion-text class="font-underline font-12" (click)="setupWebsiteType('profile')">
      {{ btn.profile }}
    </ion-text>
  </div>
</ng-template>


<ng-template #hostMsgBlock>
  <app-website-host class="full-width" [updateTheme]="updateTheme" [accountInfo]="accountInfo" [setWebsiteDetail]="websiteDetail" [mediaHeight]="height * 0.5" [fontColor]="websiteThemeColor?.font"></app-website-host>
</ng-template>

<ng-template #venueBlock>
  <app-website-venue class="full-width" [updateTheme]="updateTheme" [previewMode]="previewMode" [setWebsiteDetail]="websiteDetail" [mediaHeight]="height * 0.5" [websiteThemeColor]="websiteThemeColor" [setAccountInfo]="accountInfo" *ngIf="websiteDetail"></app-website-venue>
</ng-template>

<ng-template #galleryBlock>
  <ng-container *ngIf="!hideGallery">
    <app-website-gallery class="full-width" [updateTheme]="updateTheme" [pageMode]="true" [setWebsiteGallery]="websiteDetail?.gallery" [mediaHeight]="height * 0.5" [setReady]="ready" *ngIf="websiteDetail?.gallery?.list"></app-website-gallery>
  </ng-container>
  <div class="ion-padding-horizontal ion-text-center" *ngIf="hideGallery">
    <ion-text class="font-18 font-underline" (click)="hideGallery = false">{{ btn.gallery }}<ion-icon class="gallery-icon vertical-middle" src="./assets/wedding/icon/website/attachment/image.svg"></ion-icon></ion-text>
    <!-- <ion-button class="center" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" *ngIf="btn?.gallery" (click)="hideGallery = false">
      {{ btn.gallery }}
    </ion-button> -->
  </div>
</ng-template>

<ng-template #rsvpFormBlock>
  <app-website-form class="full-width" [landscapeMode]="landscapeMode" [updateTheme]="updateTheme" [pageMode]="true" [previewMode]="previewMode ? true : false" [accountId]="accountId" [linkId]="linkId" [accountInfo]="accountInfo" [setWebsiteDetail]="websiteDetail" [setWebsiteLink]="websiteLink" [mediaHeight]="height * 0.5" [websiteForm]="websiteDetail?.form" [websiteThemeColor]="websiteThemeColor" [guest]="guest" [group]="group" [oriMemberList]="memberList" [invitedByList]="invitedByList" [categoryList]="categoryList" [dietaryReqList]="dietaryReqList" [specialReqList]="specialReqList" [setReady]="ready" (goBack)="toggleRsvpMode()" (setGuest)="setGuest($event)" (setGroup)="setGroup($event)" (setMemberList)="setMemberList($event)"></app-website-form>
</ng-template>

<ng-template #qrcodeBlock>
  <app-website-qrcode class="full-width" [pdfMode]="pdfMode" [updateTheme]="updateTheme" [pageMode]="true" [previewMode]="previewMode ? true : false" [checkinStatus]="checkinStatus" [setWebsiteDetail]="websiteDetail" [accountInfo]="accountInfo" [websiteThemeColor]="websiteThemeColor" [mediaHeight]="height * 0.5" [setTitle]="websiteDetail?.info?.title ? websiteDetail?.info?.title : accountInfo?.title?.value" [accountId]="accountId" [linkId]="linkId" [guestId]="guestId" [groupId]="groupId" [guest]="guest" [group]="group" [memberList]="memberList" [setReady]="ready"></app-website-qrcode>
</ng-template>

<ng-template #checkinBlock>
  <app-website-checkin class="full-width" [updateTheme]="updateTheme" [pageMode]="true" [previewMode]="previewMode ? true : false" [checkinStatus]="checkinStatus" [accountInfo]="accountInfo" [setWebsiteDetail]="websiteDetail" [websiteThemeColor]="websiteThemeColor" [mediaHeight]="height * 0.5" [accountId]="accountId" [guest]="guest" [group]="group" [memberList]="memberList" [setReady]="ready"></app-website-checkin>
</ng-template>

<ng-template #contactBlock>
  <app-website-contact class="full-width" [updateTheme]="updateTheme" [pageMode]="true" [previewMode]="previewMode ? true : false" [largeMode]="websiteType === 'contact' ? true : false" [setWebsiteDetail]="websiteDetail" [mediaHeight]="height * 0.5" [setReady]="ready"></app-website-contact>
</ng-template>

<ng-template #itineraryBlock>
  <app-website-itinerary class="full-width" [updateTheme]="updateTheme" [pageMode]="true" [previewMode]="previewMode ? true : false" [accountInfo]="accountInfo" [setWebsiteDetail]="websiteDetail" [mediaHeight]="height * 0.5" [setReady]="ready"></app-website-itinerary>
</ng-template>

<ng-template #frameTopBlock>
  <ion-img class="full-width frame-top" [src]="backgroundUrl.top" *ngIf="websiteTheme?.backgroundSize === 'fixed' && backgroundUrl?.top" alt="frame-top" #frameTopImg></ion-img>
</ng-template>

<ng-template #frameBottomBlock>
  <ion-img class="full-width frame-bottom" [src]="backgroundUrl.bottom" *ngIf="websiteTheme?.backgroundSize === 'fixed' && backgroundUrl?.bottom" alt="frame-bottom" #frameBottomImg></ion-img>
</ng-template>

<ng-template #countDownBlock>
  <app-count-down class="full-width" [days]="days" [hours]="hours" [minutes]="minutes" *ngIf="days || hours || minutes"></app-count-down>
</ng-template>

<ng-template #dressCodeBlock>
  <app-website-attachment [setWebsiteAttachment]="websiteDetail?.dressCode" [attachmentType]="'dressCode'"></app-website-attachment>
</ng-template>

<ng-template #mediaBlock let-media="media">
  <div class="ion-margin-bottom" *ngIf="media?.url">
    <ng-container *ngIf="media.type === 'youtube'">
      <app-youtube [updateTheme]="updateTheme" [websiteMode]="true" [setCardHeight]="height" [setCalculateSize]="updateTheme" [setSrc]="media.url"></app-youtube>
    </ng-container>
    <ng-container *ngIf="media.type === 'base64' || media.type === 'firebase'">
      <ion-img class="full-width center" [src]="media.url"></ion-img>
    </ng-container>
  </div>
</ng-template>

<ng-template #checkinStatusBlock>
  <app-website-checkin-success [websiteDetail]="websiteDetail" [setGuest]="guest" [setGroup]="group" [setMemberList]="memberList" [setCheckinStatus]="checkinStatus"></app-website-checkin-success>
</ng-template>

<ng-template #guestProfileBlock>
  <app-website-guest-profile [setWebsiteDetail]="websiteDetail" [websiteThemeColor]="websiteThemeColor" [setGuest]="guest" [setGroup]="group" [setMemberList]="memberList" [setCheckinStatus]="checkinStatus"></app-website-guest-profile>
</ng-template>