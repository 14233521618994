import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { QrcodeTemplateService } from 'src/app/services/qrcode/qrcode-template.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GuestLogComponent } from 'src/app/components/log/guest-log/guest-log.component';
import { QrcodeTemplateComponent } from 'src/app/components/qrcode/qrcode-template/qrcode-template.component';
import { VisitorPageComponent } from 'src/app/components/visitor/visitor-page/visitor-page.component';

import { UpdateBy } from 'src/app/interfaces/user';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { QrcodeTemplate } from 'src/app/interfaces/database';

import { ErrorService } from 'src/app/services/general/error.service';
import { GroupLogComponent } from 'src/app/components/log/group-log/group-log.component';
import { ModuleType } from 'src/app/types/general';
import { ShareRecord } from 'src/app/interfaces/share';
import { ShareChannel, ShareMethod } from 'src/app/types/share';
import { TemplateVariableEvent, TemplateVariableWedding } from 'src/app/commons/template';


import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { WhatsAppDeeplink } from 'src/app/commons/url';
import { LinkService } from 'src/app/services/general/link.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';

import { VisitorLocationService } from 'src/app/services/visitor/visitor-location.service';

import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';
import { GuestListMode } from 'src/app/types/guest';
import { VisitorModeService } from 'src/app/services/visitor/visitor-mode.service';

/**
 * Guest QR code component, for preview qrcode msg, guest qr code status, and send qr code to guest.
 */
@Component({
  selector: 'app-guest-qrcode',
  templateUrl: './guest-qrcode.component.html',
  styleUrls: ['./guest-qrcode.component.scss'],
})
export class GuestQrcodeComponent implements OnInit, OnDestroy {

  mode: GuestListMode;

  newWebsiteMode: boolean;

  module: ModuleType;
  /**
   * Guest
   */
  guest: Guest;
  /**
   * Guest group
   */
  group: Group;
  /**
   * Selected template
   */
  selectedTemplate: QrcodeTemplate;

  isHybrid: boolean;

  shareRecord: ShareRecord;
  shareByName: string;

  qrcodeStatus: boolean;

  currentPrivilege: CurrentPrivilege;

  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Group list subscription
   */
  private groupListSubscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param platform Platform
   * @param modalController Modal controller
   * @param translate Translate service
   * @param qrcodeTemplateService QRcode service
   * @param accountUserService Account user service
   * @param guestListService Guest list service
   * @param groupService Guest group service
   * @param guestManageService Guest manage service
   * @param userService User service
   * @param popupService Popup service
   * @param functionService Function service
   */
  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private translate: TranslateService,
    private qrcodeTemplateService: QrcodeTemplateService,
    private privilegeService: PrivilegeService,
    private accountInfoService: AccountInfoService,
    private accountUserService: AccountUserService,
    private accountEventModeService: AccountEventModeService,
    private moduleService: ModuleService,
    private guestService: GuestService,
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private guestManageService: GuestManageService,
    private seatingSettingService: SeatingSettingService,
    private visitorLocationService: VisitorLocationService,
    private visitorModeService: VisitorModeService,
    private dateTimeService: DateTimeService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private linkService: LinkService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    this.module = this.moduleService.currentModule;
    this.selectedTemplate = { ...await this.qrcodeTemplateService.selectedTemplate() };
    this.newWebsiteMode = this.visitorModeService.checkNewWebsite();
    this.isHybrid = this.platform.is('hybrid');
    this.watch();
    this.setupTemplate();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  /**
   * Watch guest / group
   */
  watch() {
    // this.qrcodeTemplateService.watchQrcodeTemplateList();
    if (this.guest?.guestId) {
      this.watchGuest();
    } else if (this.group?.groupId) {
      this.watchGroupList();
    }
    this.watchPrivilege();
  }

  unwatch() {
    this.unwatchGuest();
    this.unwatchGroupList();
    this.unwatchPrivilege();
    // this.qrcodeTemplateService.unwatchQrcodeTemplateList();
  }

  /**
   * Watch guest list
   */
  async watchGuest() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGuest();
      });
    }
    
  }

  /**
   * Unwatch guest
   */
  async unwatchGuest() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch group list
   */
  async watchGroupList() {
    if (!this.groupListSubscription) {
      this.groupListSubscription = this.groupService.observableGroupList.subscribe((groupList: Group[]) => {
        this.setupGroup();
      });
    }
    
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    this.currentPrivilege = {
      'guest': {
        'qrcode': this.checkPrivilege('guest', 'qrcode'),
      },
    };
  }

  /**
   * Setup guest data
   */
  setupGuest() {
    if (this.guest?.guestId) {
      const guest = this.guestService.getGuest(this.guest.guestId);
      if (guest?.groupId !== this.guest?.groupId) {
        this.setupGroup();
      }
      this.guest = guest;
      this.setupShareBy();
      this.setupQrcodeStatus();
    }
  }

  /**
   * Setup group data
   */
  setupGroup() {
    if (this.group?.groupId) {
      const group = this.groupService.getGroup(this.group.groupId);
      this.group = group;
      this.setupShareBy();
      this.setupQrcodeStatus();
    }
  }

  setupShareBy() {
    this.shareRecord = this.getLastShareRecord();
    if (this.shareRecord?.by) {
      this.shareByName = this.getUserNameByUid(this.shareRecord?.by);
    }
  }

  setupQrcodeStatus() {
    this.qrcodeStatus = this.getQrcodeStatus();
  }

  /**
   * Setup template, replace dynamic variable with guest / group data.
   */
  setupTemplate() {
    if (this.selectedTemplate?.msg) {
      const variableList = this.getTemplateVariableList();
      variableList?.forEach((variable: string) => {
        const field = '[' + variable.toUpperCase() + ']';
        let value = '';
        if (this.guest) {
          if (variable === 'guest_name' && this.guest.name) {
            value = this.guest.name;
          }
          if (variable === 'guest_alternate_name' && this.guest.nickname) {
            value = this.guest.nickname;
          }
          if (variable === 'guest_seating' && this.guest.seating) {
            value = this.guest.seating ? this.guest.seating : '-';
          }
          if (variable === 'number_of_guest' || variable === 'guest_number') {
            if (this.guest?.groupId) {
              const group = this.groupService.getGroup(this.guest.groupId);
              if (group?.memberList?.length) {
                value = group.memberList.length.toString();
              } else {
                value = '1';
              }
            } else {
              value = '1';
            }
          }
          if (variable === 'group_name') {
            if (this.guest?.groupId) {
              const group = this.groupService.getGroup(this.guest.groupId);
              if (group?.groupName) {
                value = group.groupName;
              }
            }
          }
          if (variable === 'guest_session') {
            if (this.guest?.session?.[0]?.value) {
              value = this.guest.session[0].value;
            }
          }
        } else if (this.group) {
          if (variable === 'guest_name' && this.group.groupName) {
            value = this.group.groupName;
          }
          if (variable === 'group_name' && this.group?.groupName) {
            value = this.group.groupName;
          }
          if (variable === 'number_of_guest' || variable === 'guest_number') {
            if (this.group?.memberList) {
              value = this.group.memberList.length.toString();
            } else {
              value = '1';
            }
          }
          if (variable === 'guest_seating') {
            value = this.getGroupSeating();
          }
          if (variable === 'guest_session') {
            value = this.getGroupSession();
          }
        }
        if (variable === 'guest_url') {
          if (this.guest?.guestId) {
            value = this.guestService.getGuestUrl(this.guest.guestId, this.guest?.rsvp?.linkId, 'qrcode');
          } else if (this.group?.groupId) {
            value = this.groupService.getGroupUrl(this.group.groupId, this.group?.rsvp?.linkId, 'qrcode');
          }
        }
        if (variable === 'wedding_title') {
          value = this.accountInfoService.accountTitle;
        }
        if (variable === 'wedding_date') {
          if (this.accountInfoService.accountInfo?.time?.seconds) {
            value = this.format(this.accountInfoService.accountInfo.time.seconds * 1000, 'date');
          }
        }
        if (variable === 'wedding_start_time') {
          if (this.accountInfoService.accountInfo?.time?.seconds) {
            value = this.format(this.accountInfoService.accountInfo.time.seconds * 1000, 'time');
          }
        }
        if (variable === 'wedding_timezone') {
          if (this.accountInfoService.accountInfo?.timezone?.utc) {
            value = this.accountInfoService.accountInfo.timezone.utc;
          }
        }
        if (variable === 'seating_type') {
          value = this.seatingSettingService.getSeatingTypeName();
        }
        if (variable === 'wedding_venue_name' || variable === 'wedding_venue_hall' || variable === 'wedding_venue_address') {
          let langCode = 'en';
          if (!this.selectedTemplate.name?.custom && this.selectedTemplate?.name?.value) {
            langCode = this.selectedTemplate.name.value;
          }
          const venue = this.visitorLocationService.getLocation(langCode);
          if (variable === 'wedding_venue_name') {
            value = venue?.name ? venue.name : '';
          }
          if (variable === 'wedding_venue_hall') {
            value = venue?.hall ? venue.hall : '';
          }
          if (variable === 'wedding_venue_address') {
            value = venue?.address ? venue.address : '';
          }
        }
        if (variable === 'couple1' || variable ===  'couple2') {
          if (!this.accountEventModeService.eventMode) {
            if (variable === 'couple1') {
              value = this.accountInfoService.getCoupleName(1);
            } else if (variable === 'couple2') {
              value = this.accountInfoService.getCoupleName(2);
            }
          }
        } else if (variable === 'organizer') {
          if (this.accountEventModeService.eventMode) {
            value = this.accountInfoService.accountInfo?.organizer;
          }
        }
        this.selectedTemplate.msg = this.functionService.replaceAll(this.selectedTemplate.msg, field, value);
      });
    }
  }

  /**
   * Present Qr template modal
   */
  async presentQrcodeTemplateModal() {
    const modal = await this.modalController.create({
      component: QrcodeTemplateComponent,
      cssClass: '',
      componentProps: {
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      const selectedName = this.qrcodeTemplateService.selectedName;
      if (result?.data?.selected) {
        this.selectedTemplate = { ...result.data.selected };
        this.setupTemplate();
      } else if (this.selectedTemplate?.name?.value !== selectedName.value || this.selectedTemplate.name.custom !== selectedName.custom) {
        this.selectedTemplate = this.qrcodeTemplateService.getTemplateByName(selectedName);
        this.setupTemplate();
      }
    });
  }

  /**
   * Present visitor page modal for preview
   */
  async presentVisitorPageModal() {
    if (this.newWebsiteMode) {
      let url = '';
      if (this.guest?.guestId) {
        url = this.guestService.getGuestUrl(this.guest.guestId, this.guest?.rsvp?.linkId, 'qrcode');
      } else if (this.group?.groupId) {
        url = this.groupService.getGroupUrl(this.group.groupId, this.group?.rsvp.linkId, 'qrcode');
      }
      if (url) {
        this.linkService.openUrl(url);
      }
    } else {
      const modal = await this.modalController.create({
        component: VisitorPageComponent,
        cssClass: 'modal-full-screen',
        componentProps: {
          preview: true,
          accountId: this.accountInfoService.accountId,
          guestId: this.guest?.guestId,
          groupId: this.group?.groupId
        }
      });
      modal.present();
    }
  }

  async presentLogModal() {
    if (this.guest?.guestId) {
      const modal = await this.modalController.create({
        component: GuestLogComponent,
        componentProps: {
          type: 'invite',
          guestId: this.guest.guestId
        }
      });
      modal.present();
    } else if (this.group?.groupId) {
      const modal = await this.modalController.create({
        component: GroupLogComponent,
        componentProps: {
          type: 'invite',
          groupId: this.group?.groupId
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Dismiss Guest Qr modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Share QR code
   */
  async shareQr() {
    await this.popupService.presentLoading();
    if (this.platform.is('hybrid')) {
      await Share.share({
        text: this.selectedTemplate.msg
      }).then((result) => {
      }).catch((err) => {
        if (err?.message !== 'Share canceled') {
          this.errorService.logError(err);
        }
      });
    }
    this.popupService.dismissLoading();
  }

  async sendWhatsApp() {
    let mobile = '';
    if (this.group?.mobile?.no) {
      mobile = this.group.mobile.no.replace('+', '');
    } else if (this.guest?.mobile?.no) {
      mobile = this.guest.mobile.no.replace('+', '');
    }
    this.linkService.openUrl(WhatsAppDeeplink.replace('[MOBILE]', mobile).replace('[MSG]', encodeURIComponent(this.selectedTemplate?.msg ? this.selectedTemplate.msg : '')), false);
  }

  send(whatsApp?: boolean, copy?: boolean) {
    // await this.popupService.presentLoading();
    this.saveSent(whatsApp ? 'whatsapp' : '', 'url');
    if (whatsApp) {
      this.sendWhatsApp();
    } else if (copy || !this.platform.is('hybrid')) {
      this.copyMsg();
    } else {
      this.shareQr();
    }
    // await this.popupService.dismissLoading();
  }

  /**
   * Present prompt confirm send QR code modal and save send status
   */
  async promptConfirmSend(whatsApp?: boolean, copy?: boolean) {
    const sent: boolean = this.getQrcodeStatus();
    const modal = await this.popupService.presentConfirm(
      this.translate.instant(sent ? 'QRCODE.msg.update_sent' : 'QRCODE.msg.update_resend')
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        await this.popupService.presentLoading();
        await this.saveSent(whatsApp ? 'whatsapp' : '', 'url');
        await this.popupService.dismissLoading();
      }
      if (whatsApp) {
        this.sendWhatsApp();
      } else if (copy || !this.platform.is('hybrid')) {
        this.copyMsg();
      } else {
        this.shareQr();
      }
    });
  }

  /**
   * Present prompt confirm mark qr code as unsend
   * @param slidingItem Sliding item
   */
  async promptUpdateStatus(sent: boolean, slidingItem?: any) {
    const msg = sent ? this.translate.instant('QRCODE.msg.mark_as_sent') : this.translate.instant('QRCODE.msg.mark_as_unsend');
    const modal = await this.popupService.presentConfirm(msg);
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        if (sent) {
          await this.popupService.presentLoading();
          await this.saveSent('manual', '');
          await this.popupService.dismissLoading();
        } else {
          await this.saveUnsend();
        }
      }
    });
    if (slidingItem) { slidingItem.close(); }
  }

  getTemplateVariableList() {
    return this.accountEventModeService.eventMode ? TemplateVariableEvent : TemplateVariableWedding;
  }

  /**
   * Get group seating from all group guest
   * @returns Join seating from group guest
   */
  getGroupSeating(): string {
    const result = this.guestService.getGroupSeating(this.group?.memberList);
    return result.join(', ');
  }

  getGroupSession(): string {
    const result = this.guestService.getGroupSession(this.group?.memberList);
    return result.join(', ');
  }

  /**
   * Get Qrcode send status
   * @returns true if QR code sent.
   */
  getQrcodeStatus(): boolean {
    let result = false;
    if (this.group?.groupId) {
      if (this.group?.status?.qrcode) {
        result = true;
      }
    } else if (this.guest?.guestId) {
      if (this.guest?.status?.qrcode) {
        result = true;
      }
    }
    return result;
  }

  /**
   * Get last share record
   */
  getLastShareRecord(): ShareRecord {
    if (this.getQrcodeStatus()) {
      if (this.group?.shareBy?.time) {
        if (this.group.share?.by) {
          return this.group.share;
        } else {
          const share: ShareRecord  = {
            channel: '',
            type: 'group',
            method: 'url',
            by: this.group.shareBy
          };
          return share;
        }
      } else if (this.guest?.shareBy?.time) {
        if (this.guest.share?.by) {
          return this.guest.share;
        } else {
          const share: ShareRecord  = {
            channel: '',
            type: 'guest',
            method: 'url',
            by: this.guest.shareBy
          };
          return share;
        }
      }
    }
    return null;
  }

  /**
   * Get user name by UID
   * @param uid UID
   */
  getUserNameByUid(shareBy: UpdateBy) {
    const user = this.accountUserService.getUserByUid(shareBy.uid);
    if (user?.name) {
      return user.name;
    } else if (shareBy?.name) {
      return shareBy.name;
    } else {
      return null;
    }
  }

  format(timestamp: number, dateTimeFormat?: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Copy action for web, confirm to save sent status
   */
  // async copy() {
  //   const confirm = await this.popupService.presentConfirm(
  //     this.translate.instant('QRCODE.msg.mark_as_sent')
  //   );
  //   confirm.onWillDismiss().then(async (result) => {
  //     if (result?.data?.confirm) {
  //       await this.saveSent('copy', 'url');
  //     }
  //     await this.copyMsg();
  //   });
  // }

  /**
   * Copy template msg
   * @param type Share access type eg. weddingId / url
   */
  async copyMsg() {
    if (this.selectedTemplate?.msg) {
      try {
        await Clipboard.write({ string: this.selectedTemplate.msg });
        this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
      } catch (err: any) {
        this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
      }
    }
  }

  /**
   * Save guest qr code status to  unsend.
   * status.qrcode = false;
   */
  async saveUnsend() {
    await this.popupService.presentLoading();
    const status = {
      qrcode: false
    };
    if (this.group) {
      const data = {
        updateBy: this.updateByService.updateBy,
        status: { qrcode: false }
      };
      this.group.updateBy = this.updateByService.updateBy;
      this.group.status.qrcode = false;
      await this.groupManageService.saveGroupList([ this.group.groupId ], data);
      const guestIdList = this.group?.memberList.filter((guestId: string) => {
        const guest = this.guestService.getGuest(guestId);
        if (guest?.status?.qrcode) {
          return true;
        } else {
          return false;
        }
      });
      await this.guestManageService.saveGuest({ status }, guestIdList, 'invite', [], true);
    } else if (this.guest?.guestId) {
      await this.guestManageService.saveGuest({ status }, [ this.guest.guestId ], 'invite');
      this.guest.status.qrcode = false;
    }
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

  /**
   * Save sent and share log
   * @param channel share channel
   * @param type share type
   */
  async saveSent(channel: ShareChannel, method: ShareMethod) {
    const by: UpdateBy = this.updateByService.updateBy;
    const share: ShareRecord = {
      channel,
      method,
      type: this.group?.groupId ? 'group' : 'guest',
      by
    };
    const data: any = {
      share,
      status: { qrcode: true },
      shareBy: by
    };

    if (this.guest?.guestId) {
      await this.guestManageService.saveGuest(data, [ this.guest.guestId ], 'invite', [ this.guest ]);
      this.guest.status.qrcode = data.status.qrcode;
      this.guest.share = data.share;
      this.guest.shareBy = data.shareBy;
    } else if (this.group?.groupId) {
      const group = { ...this.group };
      await this.guestManageService.saveGuest(data, group?.memberList, 'invite', [], true);
      await this.groupManageService.saveGroupList([ group.groupId ], data);
      this.group.status.qrcode = data.status.qrcode;
      this.group.share = data.share;
      this.group.shareBy = data.shareBy;
    }
    await this.popupService.saveSuccessToast();
  }

}
